import { useEffect, useState, useRef } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { SpinLoader } from "./spinLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import ax, {root_api} from '../interceptors/axios';
import { useQuery } from "@tanstack/react-query";

const EmojiRating = ({ levelRequired, levelAcquired }) => {

    const [emoji, setEmoji] = useState(null);

    useEffect(() => {
        if (levelAcquired === 0 || levelAcquired === undefined) {
            setEmoji('');
        } else if (levelAcquired == levelRequired - 1) {
            setEmoji(<img src="/emoji_red_sad.png" />);
        } else if (levelAcquired < levelRequired) {
            setEmoji(<img src="/emoji_red_sad.png" />);
        } else if (levelAcquired == levelRequired) {
            setEmoji(<img src="/emoji_green_smile.png" />);
        } else if (levelAcquired > levelRequired) {
            setEmoji(<img src="/emoji_green_big.png" />);
        }
    }, [levelAcquired]);

    return (
        <>
            <div className="evaluated-emoji-container">{emoji}</div>
        </>
    );
};

const Header = ({allEvaluationDone}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { isFetching, data: allCards, refetch } = useQuery({
        enabled: allEvaluationDone,
        cacheTime: 24 * 60 * (60 * 1000),
        staleTime: 24 * 60 * (60 * 1000),
        queryKey: ['evaluatedCardsData', searchParams.get('page'), searchParams.get('searchQuery'), searchParams.get('order')],
        queryFn: () => {
            return ax.get(`evaluated/cards/`)
            .then(res => {
                console.log("ALL EVALUATED DATA: ", res);
                return res.data
            }
            )
        }
    })

    const getSkillValue = (evaluations, skillId, lvlrequired) => {
        const evaluation = evaluations?.evaluatedResults?.find(evaluation => evaluation.skill_id === skillId)
        if (!evaluation) return 'N/A';
        return <EmojiRating levelRequired={lvlrequired} levelAcquired={evaluation.levelAcquired} />
        return `${evaluation.levelAcquired}/${lvlrequired}`
    }

    return (
        <>
            <p className="title-page">Campagne d'appréciation<br></br>des compétences</p>
            <div className="listofcollaborators-header">
                {allEvaluationDone && <div className="listofcollaborators-header-container">
                    <div className="listofcollaborators-header-card">
                        <div className="listofcollaborators-header-card-content">
                            <div className="listofcollaborators-header-card-container">
                                {!isFetching?<div className="cardEvaluations-value">
                                    {allCards.map((card, index) => (
                                        <div className="cardEvaluations-value-container" key={index}>
                                            <div className="cardEvaluations-value-employee">
                                                <img src={card.profile_picture.replace('static/public/', '')} alt="avatar" width="40rem" height="40rem" class="rounded-circle" style={{ margin: '5px' }} />
                                                <div className="cardEvaluations-value-employee-identity">
                                                    <div className="cardEvaluations-value-employee-name">{card.first_name + ' ' + card.last_name}</div>
                                                    <div className="cardEvaluations-value-employee-poste">{card.position.title}</div>
                                                </div>
                                                <div className="cardEvaluations-value-employee-note-each-skill">
                                                    {card.skills.map((skill, index) => (
                                                        <div className="cardEvaluations-value-employee-note-each-skill-container" key={index}>
                                                            <div className="cardEvaluations-value-employee-note-each-skill-title">{skill.skill.title}</div>
                                                            <div className="cardEvaluations-value-employee-note-each-skill-value">{getSkillValue(card.evaluations, skill.skill.id, skill.levelRequired)}</div>
                                                        </div>)
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>:<SpinLoader/>}
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            <div className="search-bar-container">
                <input type="search" placeholder="Recherche collaborateur" className="search-input" value={searchParams.get('searchQuery') ?? ''} onChange={(e) => {
                    if (!e.target.value) {
                        setSearchParams({
                            ...searchParams.delete('searchQuery'),
                            order: searchParams.get('order'),
                            page: 1
                        })
                    } else {
                        setSearchParams({
                            searchQuery: e.target.value,
                            order: searchParams.get('order'),
                            page: 1
                        })
                    }
                }} />
            </div>
        </>
    )
}

const Body = ({ list }) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isFiltreEvaluated, setIsFiltreEvaluated] = useState(searchParams.get('order') === 'true' ? true : false);

    useEffect(() => {
        setSearchParams({
            order: isFiltreEvaluated,
            page: searchParams.get('page') ?? 1,
            searchQuery: searchParams.get('searchQuery') ?? ''
        })
    }, [isFiltreEvaluated])

    const handleClick = () => {
        setIsFiltreEvaluated(!isFiltreEvaluated)
    }

    return (
        <table className="listofcollaborators-table">

            <thead>
                <tr className="listofcollaborators-table-tr">
                    <th>Collaborateur</th>
                    <th>Date d'intégration</th>
                    <th>Statut<div className="listofcollaborators-table-filterbystatus" onClick={()=>handleClick()} style={{cursor:'pointer'}}><i class="bi bi-arrow-down-up"></i></div></th>
                </tr>
            </thead>
            <tbody>
                {
                    list.results?.map((evaluated, index) => (
                        <tr key={evaluated.id} onClick={() => {
                            navigate(`../evaluated/${evaluated.id}`, {
                                state: {
                                    page: searchParams.get('page'),
                                    query: searchParams.get('searchQuery'),
                                    order: searchParams.get('order')
                                }
                            })
                        }} style={{ backgroundColor: index % 2 === 0 ? '#f4f6fa' : 'white', cursor: 'pointer' }} >
                            <td>
                                <div className="listofcollaborators-table-employee-container">
                                    <img src={evaluated.profile_picture.replace('static/public/', '')} alt="avatar" width="60rem" height="60rem" class="rounded-circle" style={{ margin: '5px' }} />
                                    <div className="listofcollaborators-table-employee-identity">
                                        <div className="listofcollaborators-table-employee-name">{evaluated.first_name + ' ' + evaluated.last_name}</div>
                                        <div className="listofcollaborators-table-employee-poste">{evaluated.position.intitule}</div>
                                    </div>
                                </div>
                            </td>
                            <td><div className="listofcollaborators-table-info">{evaluated.date_integration}</div></td>
                            <td><div className={evaluated.isEvaluated ? "listofcollaborators-table-isevaluated" : "listofcollaborators-table-notevaluated"}></div></td>
                        </tr>
                    ))}
            </tbody>
        </table>
    )
}

const Footer = ({ pageCount }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLastPage, setIsLastPage] = useState(false)
    const [isFirstPage, setIsFirstPage] = useState(false)

    useEffect(() => {
        const page = Number(searchParams.get('page'))
        if (page) {
            setIsLastPage(Math.ceil(pageCount / 10) <= page)
            setIsFirstPage(page === 1)
        }
    }, [searchParams])


    return (
        <>
            <div className="listofcollaborators-pagehandler" >
                <div className="listofcollaborators-pagehandler-container">
                    <button type="button" className="listofcollaborators-pagehandler-btn" disabled={isFirstPage} onClick={() => {
                        setSearchParams({
                            searchQuery: searchParams.get('searchQuery')??'',
                            order: searchParams.get('order'),
                            page: 1
                        })
                    }}><i className="bi bi-chevron-double-left"></i></button>
                    <button type="button" className="listofcollaborators-pagehandler-btn" disabled={isFirstPage} onClick={() => {
                        setSearchParams({
                            searchQuery: searchParams.get('searchQuery')??'',
                            order: searchParams.get('order'),
                            page: Number(searchParams.get('page')) - 1
                        })
                    }}><i className="bi bi-chevron-left"></i></button>
                    <div className="listofcollaborators-pagehandler-page">{searchParams.get('page')}</div>
                    <button type="button" className="listofcollaborators-pagehandler-btn" disabled={isLastPage} onClick={() => {
                        setSearchParams({
                            searchQuery: searchParams.get('searchQuery')??'',
                            order: searchParams.get('order'),
                            page: Number(searchParams.get('page')) + 1
                        })
                    }}><i className="bi bi-chevron-right"></i></button>
                    <button type="button" className="listofcollaborators-pagehandler-btn" disabled={isLastPage} onClick={() => {
                        setSearchParams({
                            searchQuery: searchParams.get('searchQuery')??'',
                            order: searchParams.get('order'),
                            page: Math.ceil(pageCount / 10)
                        })
                    }}><i className="bi bi-chevron-double-right"></i></button>
                    {(Math.ceil(pageCount / 10) == 1)?'':<div className="listofcollaborators-pagehandler-page" style={{backgroundColor:'#f0f0f0', color: '#20418e'}}>{Math.ceil(pageCount / 10)}</div>}
                </div>
            </div >

        </>
    )
}


export const ListOfCollaborators = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [showCardEvaluated, setShowCardEvaluated] = useState(false)

    const { isFetching, data: list, isPreviousData, refetch } = useQuery({
        enabled: true,
        queryKey: ['listofcollabs', searchParams?.get('page'), searchParams?.get('searchQuery'), searchParams?.get('order')],
        cacheTime: 24 * 60 * (60 * 1000),
        staleTime: 24 * 60 * (60 * 1000),
        queryFn: () => {
            return ax.get('evaluated/owned/', {
                params: {
                    search: (searchParams.get('searchQuery')?.length > 2) ? searchParams.get('searchQuery') : '',
                    page: searchParams?.get('page') ?? 1,
                    order: searchParams.get('order') ?? true
                }
            }).then(res => {
                res.data.results.some(obj => obj.isEvaluated === false) ? setShowCardEvaluated(false) : setShowCardEvaluated(true);
                console.log("preshowcard: ", showCardEvaluated)
                return res.data
            })
        }
    })


    useEffect(() => {
        if (!searchParams.get('page')) {
            setSearchParams({
                order: true,
                page: 1
            })
        }
    }, [searchParams])

    return (
        <div className="listofcollaborators-container">
            <Header allEvaluationDone={showCardEvaluated}/>
            {isFetching ? <SpinLoader /> : !list?.results?.length ?
                <>
                <div className="listofcollaborators-content">
                    <div className="listofcollaborators-card" style={{ backgroundColor: 'white' }}>
                        <div className="listofcollaborators-card-content">
                            <div className="listofcollaborators-card-container">
                                <p className="card-title">Aucun collaborateur {searchParams.searchQuery?.length ? 'trouvé' : 'vous a été assigné'}.</p>
                            </div>
                        </div>
                    </div>
                </div></>
                :
                <>
                    <Body list={list} />
                    <Footer pageCount={list?.count} />
                </>
            }
        </div>
    );
};







