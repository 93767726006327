import { Navigate, useNavigate } from "react-router-dom";
import {Page404} from "./Page404";
import {useUser} from "../context/UserContext";
import { ResetPsw } from "./resetpsw";
import { useEffect, useState } from "react";


export const ProtectedRoute = ({ children, isAdmin }) => {
  const navigate = useNavigate();
  const user = useUser();

  useEffect(() => {
    console.log(localStorage.getItem('first_login'));
    if ((localStorage.getItem('first_login') == true) || (localStorage.getItem('first_login') == 'true')) {
      navigate('/resetpsw');
      return; 
    }

  }, []);

  if (!(localStorage.getItem('access_token') !== null)) {
    return <Navigate to="/login" />;
  } else if (isAdmin && user?.user === null) {
    return <Page404/>;
  } else if (isAdmin && !user?.user.roles.includes('ADMIN')) {
    return <Page404/>;
  }
  return children;
};