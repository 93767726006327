import ax from '../interceptors/axios';
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useUser } from '../context/UserContext';

export const Logout = () => {
    const navigate = useNavigate();
    const setUser = useUser().setUser;

    const { isFetching, error } = useQuery({
        enabled: true,
        queryFn: ({ signal }) => {
            return ax.post('logout/', {
                signal,
                params: {
                    refresh_token: localStorage.getItem('refresh_token'),
                }
            }).then(res => {
                localStorage.clear();
                setUser(null);
                window.location.href = '/login';
            }).catch(err => {
                localStorage.clear();  
                setUser(null);
                window.location.href = '/login';
            })
        }
    })

    if (isFetching) return (<div>t</div>);
}