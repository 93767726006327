import { useEffect, useState, useRef } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { SpinLoader } from "./spinLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import ax, {root_api} from '../interceptors/axios';
import { useQuery, useMutation } from "@tanstack/react-query";
import Notification from './Notifications';

const AddCampaign = ({ setShowAddcampaign }) => {
    const [showNotification, setShowNotification] = useState([false, '', 'error']);
    const [isLoading, setIsLoading] = useState(false);

    const createCampaign = useMutation({
        mutationFn: (new_campaign) => {
            const formData = new FormData();
            Object.keys(new_campaign).forEach((key) => {
                if (key !== 'import_matrice') {
                    formData.append(key, new_campaign[key]);
                }
            });

            formData.append('matrice_file', new_campaign.import_matrice);

            return ax.post('campaign/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                responseType: 'blob'
            }).then(res => {
                console.log("NEW CAMPAIGN ADDED", res.data)
                if (res.data?.message == undefined || res.data?.size > 16) {
                    const file = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    const fileURL = URL.createObjectURL(file);
                    setShowNotification([true, "Campagne ajouté mais des erreurs sont survenus, merci de télécharger le fichier pour en savoir plus.", 'warning']);
                    const a = document.createElement('a');
                    a.href = fileURL;
                    a.download = 'error_file.xlsx';
                    a.click();
                    URL.revokeObjectURL(fileURL);
                    setIsLoading(false);
                    setTimeout(() => {
                        window.location.reload();
                    }, 10000);
                } else if (res.data?.message === 'ok' || res.data?.size <= 16) {
                    
                    console.log("ERROR FILE CAMPAIGN: ", res.data?.error_file)
                    
                    setShowNotification([true, 'Campagne ajouté avec succès', 'success']);
                    setIsLoading(false);
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                    
                } else {
                    setIsLoading(false);
                    const _msg = !(res.data?.message) ? res.response.data.detail : res.data.message;
                    if (_msg == undefined) {
                        _msg = res.data;
                    }
                    setShowNotification([true, _msg, 'error']);
                }
                return res.data
            })
                .catch(err => {
                    setIsLoading(false);
                    console.log("ERROR ADDING CAMPAIGN", err.response.data)
                    setShowNotification([true, err.response.data.message, 'error']);
                })
        }
    })


    const onSubmitAddCampaign = (e) => {
        e.preventDefault();

        if (e.target.start_date.value > e.target.end_date.value) {
            setShowNotification([true, 'La date de début doit être inférieure à la date de fin', 'error']);
            return;
        }

        const new_campaign = {
            title: e.target.title.value,
            description: e.target.description.value,
            start_date: e.target.start_date.value,
            end_date: e.target.end_date.value,
            import_matrice: e.target.import_matrice.files[0] ?? ''
        }

        if (!isLoading) {
            setIsLoading(true);
            createCampaign.mutate(new_campaign);
        }

    };

    const onShowAddcampaign = () => {
        setShowAddcampaign(false);
    }

    const closeNotification = () => {
        setShowNotification([false, '', 'error']);
    };

    return isLoading ? (<div className="admin-adduser-container">
        <div className="admin-adduser-title"><p>La campagne est en cours de création</p></div>
        <SpinLoader />
    </div>) : (<div className="admin-adduser-container">
        <div className="admin-adduser-title"><p>Commencer une nouvelle campagne</p><i class="bi bi-x-square-fill" onClick={() => onShowAddcampaign()}></i></div>
        <form onSubmit={onSubmitAddCampaign}>
            <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Titre</label>
                        <input type="input" placeholder='Titre' required id="title"></input>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Description (optionel)</label>
                        <input type="input" placeholder='Description' id="description"></input>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Date Debut</label>
                        <input type="date" required id="start_date"></input>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Date Fin</label>
                        <input type="date" required id="end_date"></input>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <label>Importation fichier EXCEL</label>
                    <input type="file" id="import_matrice"></input>
                </div>
            </div>
            {showNotification[0] ? <div className="resetpsw-form-group" style={{ marginTop: '1rem' }}>
                <Notification message={showNotification[1]} type={showNotification[2]} onClose={closeNotification} />

            </div> : null}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                <button type="submit" className="adduser-btn-submit" disabled={isLoading}>Ajouter</button>
            </div>
        </form>
    </div>);
}


const EditCampaign = ({ setShowEditcampaign, campaign }) => {
    const [showNotification, setShowNotification] = useState([false, '', 'error']);
    const [isLoading, setIsLoading] = useState(false);
    const [endDate, setEndDate] = useState(campaign.end_date);


    const { isFetching, data: currentCampaign } = useQuery({
        enabled: true,
        queryKey: ['currentCampaign'],
        queryFn: () => {
            return ax.get('campaign/current/').then(res => {
                console.log("CURRENT CAMPAIGN", res.data[0])
                if (res.data.length) {
                    return res.data[0]
                }
                return null;
            })
        }
    })

    const updateCampaign = useMutation({
        mutationFn: (new_campaign) => {
            const formData = new FormData();
            Object.keys(new_campaign).forEach((key) => {
                if (key !== 'import_matrice') {
                    formData.append(key, new_campaign[key]);
                }
            });

            formData.append('import_matrice', new_campaign.import_matrice);

            return ax.patch(`campaign/${campaign.id}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                responseType: 'blob'
            }).then(res => {
                console.log("CAMPAIGN EDITED", res)
                if (res.data?.size > 16) {
                    const file = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    const fileURL = URL.createObjectURL(file);
                    setShowNotification([true, "Campagne modifier mais des erreurs sont survenus, merci de télécharger le fichier pour en savoir plus.", 'warning']);
                    const a = document.createElement('a');
                    a.href = fileURL;
                    a.download = 'error_file.xlsx';
                    a.click();
                    URL.revokeObjectURL(fileURL);
                    setIsLoading(false);
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                } else if (res.data?.message === 'ok' || res.data?.size <= 16) {
                    setShowNotification([true, 'Campagne modifier avec succès', 'success']);
                    setIsLoading(false);
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                } else {
                    setIsLoading(false);
                    const _msg = !(res.data?.message) ? res.response.data.detail : res.data.message;
                    if (_msg == undefined) {
                        _msg = res.data;
                    }
                    setShowNotification([true, _msg, 'error']);
                }
                return res.data
            })
                .catch(err => {
                    setIsLoading(false);
                    console.log("ERROR EDITING CAMPAIGN", err.response.data)
                    setShowNotification([true, err.response.data.message, 'error']);
                })
        }
    })

    const onSubmitEditCampaign = (e) => {
        e.preventDefault();

        if (e.target.import_matrice.files[0] == null && endDate === campaign.end_date) {
            setShowNotification([true, "Vous n'avez rien modifier.", 'warning']);
            return;
        }

        if (endDate < campaign.start_date) {
            setShowNotification([true, 'La date de fin doit être supérieure à la date de début', 'error']);
            return;
        }

        console.log("END DATE", endDate);

        const new_campaign = {
            end_date: endDate ?? '',
            import_matrice: e.target.import_matrice.files[0] ?? '',
        }

        if (!isLoading) {
            setIsLoading(true);
            updateCampaign.mutate(new_campaign);
        }

    };

    const onShowEditcampaign = () => {
        setShowEditcampaign(false);
    }

    const closeNotification = () => {
        setShowNotification([false, '', 'error']);
    };


    if (isFetching) return (<div className="admin-adduser-container"><SpinLoader /></div>)
    return isLoading ? (<div className="admin-adduser-container">
        <div className="admin-adduser-title"><p>La campagne "{campaign.title}" est en cours de modification</p></div>
        <SpinLoader />
    </div>) : (<div className="admin-adduser-container">
        <div className="admin-adduser-title"><p>Modifier la campagne: {campaign.title}</p><i class="bi bi-x-square-fill" onClick={() => onShowEditcampaign()}></i></div>
        <form onSubmit={onSubmitEditCampaign}>
            <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Titre</label>
                        <input type="input" placeholder='Titre' disabled value={campaign.title}></input>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Description (optionel)</label>
                        <input type="input" placeholder='Description' disabled value={campaign.description}></input>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Date Debut</label>
                        <input type="date" disabled value={campaign.start_date}></input>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Date Fin</label>
                        <input id="end_date" type="date" default={endDate} value={endDate} onChange={(e)=>{setEndDate(e.target.value)}}></input>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <label>Importation fichier EXCEL</label>
                    <input type="file" id="import_matrice"></input>
                </div>
            </div>
            {showNotification[0] ? <div className="resetpsw-form-group" style={{ marginTop: '1rem' }}>
                <Notification message={showNotification[1]} type={showNotification[2]} onClose={closeNotification} />
            </div> : null}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                <button type="submit" className="adduser-btn-submit" disabled={isLoading}>Mettre à jour</button>
            </div>
        </form>
    </div>);
}



const DeleteCampaign = ({ setShowDeleteCampaign, selectedCampaign, seeArchived }) => {
    const [showNotification, setShowNotification] = useState([false, '', 'error']);

    const deleteCampaign = useMutation({
        mutationFn: () => {
            return ax.post(`campaign/${selectedCampaign.id}/archive/`)
            .then(res => {
                if (res.status === 204) {
                    setShowNotification([true, 'Opération réalisée avec succès', 'success']);
                    console.log("CAMPAIGN DELETING", res)
                    setTimeout(() => {
                        console.log("RELOADING PAGE")
                        window.location.reload();
                    }, 500);
                } else {
                    console.log("ERROR DELETING CAMPAIGN", res.response.data.detail)
                    const _msg = !(res.data?.message)?res.response.data.detail:res.data.message;
                    if (_msg == undefined) {
                        _msg = res.data;
                    }
                    setShowNotification([true, _msg, 'error']);
                    return ;
                }
                return res.data
            })
        }
    })

    const onSubmitDeleteCampaign = () => {
        deleteCampaign.mutate();
    };

    const onShowDeletecampaign = () => {
        setShowDeleteCampaign(false);
    }

    const closeNotification = () => {
        setShowNotification([false, '', 'error']);
    };

    return (<div className="admin-adduser-container">
        <div className="admin-adduser-title">
            <p>êtes-vous sûr de vouloir {seeArchived?'désarchiver':'archiver'} la campagne "{selectedCampaign.title}" ?</p><i class="bi bi-x-square-fill" onClick={() => onShowDeletecampaign()}></i></div>
            {showNotification[0] ? <div className="resetpsw-form-group" style={{marginTop: '1rem'}}>
                    <Notification message={showNotification[1]} type={showNotification[2]} onClose={closeNotification} />
                </div>: null}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                <button type="button" onClick={onSubmitDeleteCampaign} className="adduser-btn-submit">Comfirmer</button>
        </div>
    </div>);
}


const ReportCampaign = ({ setShowReporting, selectedCampaign }) => {
    const [showNotification, setShowNotification] = useState([false, '', 'error']);
    const [isLoading, setIsLoading] = useState(false);

    console.log("SELECTED CAMPAIGN", selectedCampaign)

    const reportingCampaign = useMutation({
        mutationFn: (new_campaign) => {
            const formData = new FormData();
            Object.keys(new_campaign).forEach((key) => {
                formData.append(key, new_campaign[key]);
            });

            return ax.get(`campaign/${selectedCampaign.id}/reporting/`, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                responseType: 'blob'
            })
            .then(res => {
                console.log("CAMPAIGN REPORTING", res)
                if (res.status === 200) {
                    setShowNotification([true, 'Reporting crée avec succès', 'success']);
                    const file = new Blob([res.data], {type: res.headers["Content-Type"]});
                    const fileURL = URL.createObjectURL(file);
                    const a = document.createElement('a');
                    a.href = fileURL;
                    let today = new Date().toISOString().slice(0, 10)
                    a.download = `reporting_${selectedCampaign.id}_${selectedCampaign.title}_${today}.xlsx`;
                    a.click();
                    URL.revokeObjectURL(fileURL);
                    setIsLoading(false);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                } else {
                    setIsLoading(false);
                    const _msg = !(res.data?.message) ? res.response.data.detail : res.data.message;
                    if (_msg == undefined) {
                        _msg = res.data;
                    }
                    setShowNotification([true, _msg, 'error']);
                }
                return res.data
            })
                .catch(err => {
                    setIsLoading(false);
                    console.log("ERROR REPORTING CAMPAIGN", err.response.data)
                    setShowNotification([true, err.response.data.message, 'error']);
                })
        }
    })

    const onSubmitEditCampaign = (e) => {
        e.preventDefault();

        const new_campaign = {
            id: selectedCampaign.id,
        }

        if (!isLoading) {
            setIsLoading(true);
            reportingCampaign.mutate(new_campaign);
        }

    };


    const onShowEditcampaign = () => {
        setShowReporting(false);
    }

    const closeNotification = () => {
        setShowNotification([false, '', 'error']);
    };

    return isLoading ? (<div className="admin-adduser-container">
        <div className="admin-adduser-title"><p>Le reporting de la campagne {selectedCampaign.title} est en cours de préparation</p></div>
        <SpinLoader />
    </div>) : (<div className="admin-adduser-container">
        <div className="admin-adduser-title"><p>Télécharger le reporting de la campagne: {selectedCampaign.title} ?</p><i class="bi bi-x-square-fill" onClick={() => onShowEditcampaign()}></i></div>
        <form onSubmit={onSubmitEditCampaign}>
            {showNotification[0] ? <div className="resetpsw-form-group" style={{ marginTop: '1rem' }}>
                <Notification message={showNotification[1]} type={showNotification[2]} onClose={closeNotification} />
            </div> : null}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                <button type="submit" className="adduser-btn-submit" disabled={isLoading}>Télécharger</button>
            </div>
        </form>
    </div>);
}

const Header = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [showAddcampaign, setShowAddcampaign] = useState(false);

    const onShowAddcampaign = () => {
        setShowAddcampaign(!showAddcampaign);
    }

    return (
        <>
            <div className="search-bar-container">
                <div className="search-bar-btn-ajouteruser" onClick={onShowAddcampaign}><p>Commencer une nouvelle campagne</p></div>
                <input type="search" placeholder="Recherche campagne" className="search-input" value={searchParams.get('searchQuery') ?? ''} onChange={(e) => {
                    if (!e.target.value) {
                        setSearchParams({
                            ...searchParams.delete('searchQuery'),
                            page: 1
                        })
                    } else {
                        setSearchParams({
                            searchQuery: e.target.value,
                            page: 1
                        })
                    }
                }} />
            </div>
            {showAddcampaign ? <AddCampaign setShowAddcampaign={setShowAddcampaign} /> : null}
        </>
    )
}


const Body = ({ list }) => {
    const [showChangecampaign, setShowChangecampaign] = useState(false);
    const [showDeleteCampaign, setShowDeleteCampaign] = useState(false);
    const [showEditcampaign, setShowEditcampaign] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [campaign, setCampaign] = useState(null);
    const [seeArchived, setSeeArchived] = useState(false);
    const [showReporting, setShowReporting] = useState(false);
    const [filtreDateDebut, setFiltreDateDebut] = useState(searchParams.get('orderdd') === 'true' ? true : false);
    const [filtreDateFin, setFiltreDateFin] = useState(searchParams.get('orderdf') === 'true' ? true : false);

    const onShowChangecampaign = (_campaign) => {
        setCampaign(_campaign);
        setShowChangecampaign(!showChangecampaign);
    }

    const handleDeleteClick = (e, _campaign) => {
        setCampaign(_campaign);
        setShowDeleteCampaign(true);
        e.stopPropagation();
    }

    const handleEditClick = (e, _campaign) => {
        setCampaign(_campaign);
        setShowEditcampaign(true);
        e.stopPropagation();
    }

    const handleSeeArchvied = () => {
        setSeeArchived(!seeArchived);
    }

    const handleReportingClick = (e, _campaign) => {
        setCampaign(_campaign);
        setShowReporting(true);
        e.stopPropagation();
    }

    const handleFiltreDateDebut = () => {
        setFiltreDateDebut(!filtreDateDebut);
    }

    const handleFiltreDateFin = () => {
        setFiltreDateFin(!filtreDateFin);
    }

    useEffect(() => {
        setSearchParams({
            searchQuery: searchParams.get('searchQuery')??'',
            page: searchParams.get('page')??1,
            orderdd: filtreDateDebut,
            orderdf: filtreDateFin
        })
    }, [filtreDateDebut, filtreDateFin])

    return (
        <>
        <table className="listofcollaborators-table">

            <thead>
                <tr className="listofcollaborators-table-tr" >
                    <th style={{ textAlign: 'center' }}>Campagne</th>
                    <th>Date de début<div className="listofcollaborators-table-filterbydate" onClick={()=>handleFiltreDateDebut()} style={{cursor:'pointer'}}><i class="bi bi-arrow-down-up"></i></div></th>
                    <th>Date de fin<div className="listofcollaborators-table-filterbydate" onClick={()=>handleFiltreDateFin()} style={{cursor:'pointer'}}><i class="bi bi-arrow-down-up"></i></div></th>
                    <th>Evaluation restante</th>
                    <th>Mettre à jour</th>
                    <th>Repporting</th>
                    <th>{seeArchived?'Désarchiver':'Archiver'}<div className="listofcollaborators-table-filterbydate" onClick={()=>handleSeeArchvied()} style={{cursor:'pointer'}}><i class="bi bi-arrow-repeat"></i></div></th>
                </tr>
            </thead>
            <tbody>
                {
                    list?.results?.filter((c) => (c.isArchived == seeArchived)).map((campaign, index) => (
                        <tr key={campaign.id} style={{ backgroundColor: index % 2 === 0 ? '#f4f6fa' : 'white' }} onClick={()=>onShowChangecampaign(campaign)}>
                            <td style={{ textAlign: 'center', fontWeight: '800' }}>{campaign.isCurrent ? <i class="bi bi-check-circle-fill"></i> : null} {campaign.title}</td>
                            <td style={{ textAlign: 'center', fontWeight: '500' }}>{campaign.start_date}</td>
                            <td style={{ textAlign: 'center', fontWeight: '500' }}>{campaign.end_date}</td>
                            <td style={{ textAlign: 'center', fontWeight: '800' }}>{campaign.evaluationsLeft}/{campaign.evaluationsTotal}</td>
                            <td className="admincampaign-edit-button" style={{ textAlign: 'center', fontWeight: '800', cursor: 'pointer'}} onClick={(e)=>handleEditClick(e, campaign)}><i class="bi bi-pen-fill"></i></td>
                            <td className="admincampaign-reporting-button" style={{ textAlign: 'center', fontWeight: '800', cursor: 'pointer'}} onClick={(e)=>handleReportingClick(e, campaign)}><i class="bi bi-table"></i></td>
                            <td className="admincampaign-archive-button" style={{ textAlign: 'center', fontWeight: '800', cursor: 'pointer'}} onClick={(e)=>handleDeleteClick(e, campaign)}><i class={seeArchived?"bi bi-capslock-fill":"bi bi-archive-fill"}></i></td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
        {showReporting ? <ReportCampaign setShowReporting={setShowReporting} selectedCampaign={campaign} /> : null}
        {showEditcampaign ? <EditCampaign setShowEditcampaign={setShowEditcampaign} campaign={campaign} /> : null}
        {showDeleteCampaign ? <DeleteCampaign setShowDeleteCampaign={setShowDeleteCampaign} selectedCampaign={campaign} seeArchived={seeArchived}/>: null}
        </>
    )
}


const Footer = ({ pageCount }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLastPage, setIsLastPage] = useState(false)
    const [isFirstPage, setIsFirstPage] = useState(false)

    useEffect(() => {
        const page = Number(searchParams.get('page'))
        if (page) {
            setIsLastPage(Math.ceil(pageCount / 10) <= page)
            setIsFirstPage(page === 1)
        }
    }, [searchParams])


    return (
        <>
            <div className="listofcollaborators-pagehandler" >
                <div className="listofcollaborators-pagehandler-container">
                    <button type="button" className="listofcollaborators-pagehandler-btn" disabled={isFirstPage} onClick={() => {
                        setSearchParams({
                            searchQuery: searchParams.get('searchQuery')??'',
                            orderdd: searchParams.get('orderdd'),
                            orderdf: searchParams.get('orderdf'),
                            page: 1
                        })
                    }}><i className="bi bi-chevron-double-left"></i></button>
                    <button type="button" className="listofcollaborators-pagehandler-btn" disabled={isFirstPage} onClick={() => {
                        setSearchParams({
                            searchQuery: searchParams.get('searchQuery')??'',
                            orderdd: searchParams.get('orderdd'),
                            orderdf: searchParams.get('orderdf'),
                            page: Number(searchParams.get('page')) - 1
                        })
                    }}><i className="bi bi-chevron-left"></i></button>
                    <div className="listofcollaborators-pagehandler-page">{searchParams.get('page')}</div>
                    <button type="button" className="listofcollaborators-pagehandler-btn" disabled={isLastPage} onClick={() => {
                        setSearchParams({
                            searchQuery: searchParams.get('searchQuery')??'',
                            orderdd: searchParams.get('orderdd'),
                            orderdf: searchParams.get('orderdf'),
                            page: Number(searchParams.get('page')) + 1
                        })
                    }}><i className="bi bi-chevron-right"></i></button>
                    <button type="button" className="listofcollaborators-pagehandler-btn" disabled={isLastPage} onClick={() => {
                        setSearchParams({
                            searchQuery: searchParams.get('searchQuery')??'',
                            orderdd: searchParams.get('orderdd'),
                            orderdf: searchParams.get('orderdf'),
                            page: Math.ceil(pageCount / 10)
                        })
                    }}><i className="bi bi-chevron-double-right"></i></button>
                    {(Math.ceil(pageCount / 10) == 1)?'':<div className="listofcollaborators-pagehandler-page" style={{backgroundColor:'#f0f0f0', color: '#20418e'}}>{Math.ceil(pageCount / 10)}</div>}
                </div>
            </div >

        </>
    )
}

export const AdminCampaign = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { isFetching, data: listCampaign, isPreviousData, refetch } = useQuery({
        enabled: true,
        queryKey: ['listofcampaign', searchParams?.get('page'), searchParams?.get('searchQuery'), searchParams?.get('orderdd'), searchParams?.get('orderdf')],
        cacheTime: 24 * 60 * (60 * 1000),
        staleTime: 24 * 60 * (60 * 1000),
        queryFn: () => {
            return ax.get('campaign/', {
                params: {
                    search: (searchParams.get('searchQuery')?.length > 2) ? searchParams.get('searchQuery') : '',
                    orderdd: searchParams.get('orderdd'),
                    orderdf: searchParams.get('orderdf'),
                    page: searchParams?.get('page') ?? 1,
                }
            }).then(res => {
                console.log("DATA FOR ADMIN CAMPAIGN", res.data)
                return res.data
            })
        }
    })

    useEffect(() => {
        if (!searchParams.get('page')) {
            setSearchParams({
                searchQuery: searchParams.get('searchQuery')??'',
                orderdd: searchParams.get('orderdd'),
                orderdf: searchParams.get('orderdf'),
                page: 1
            })
        }
        refetch();
    }, [searchParams])

    return (
        <>
            <Header key="campaignheader"/>
            {isFetching ? <SpinLoader /> : !listCampaign?.results?.length ?
                <div className="listofcollaborators-content">
                    <div className="listofcollaborators-card" style={{ backgroundColor: 'white' }}>
                        <div className="listofcollaborators-card-content">
                            <div className="listofcollaborators-card-container">
                                <p className="card-title">Aucune campagne trouvé.</p>
                            </div>
                        </div>
                    </div>
                </div>
                : <div style={{ marginBottom: '4rem' }}>
                    <Body list={listCampaign} />
                    <Footer pageCount={listCampaign?.count} />
                </div>}
        </>);
};
