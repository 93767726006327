import React, { useState, useEffect } from 'react';
import "bootstrap-icons/font/bootstrap-icons.css";
import { useUser } from '../context/UserContext';
import { useMutation, useQuery } from "@tanstack/react-query";
import ax from '../interceptors/axios';
import Notification from './Notifications';
import { SpinLoader } from './spinLoader';

export function ResetPsw() {
    const user = useUser().user;
    const [showNotification, setShowNotification] = useState([false, '', 'error']);
    const [isLoading, setIsLoading] = useState(false);
    const mutation = useMutation({
        mutationFn: (resetpswdParam) => {
            setIsLoading(true);
            console.log(localStorage.getItem('access_token'));
            return ax.post(`users/${user.id}/rstpswd/`, {
                params: resetpswdParam,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }, { withCredentials: true })
            .then(res => {
                if (res.status !== 200) {
                    setShowNotification([true, res.response.data.detail, 'warning']);
                } else {
                    setShowNotification([true, "Password reseted successfully", 'success']);
                    window.location.href = '/logout';
                }
                setIsLoading(false);
                return res.data;
            }).catch(err => {
                setShowNotification([true, "An error occured during password reset, please retry in a few minutes.", 'error']);
                setIsLoading(false);
                return err;
            });
        }
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        const oldpsw = e.target.oldpsw.value;
        const newpsw = e.target.newpsw.value;
        const confirmpsw = e.target.confirmpsw.value;

        if (newpsw !== confirmpsw) {
            setShowNotification([true, "New password and confirm password are not the same", 'error']);
            return;
        }
        mutation.mutate({
            old_password: oldpsw,
            new_password: newpsw
        });
        e.target.oldpsw.value = '';
        e.target.newpsw.value = '';
        e.target.confirmpsw.value = '';
    }

    const closeNotification = () => {
        setShowNotification([false, '', 'error']);
    };

    return (<div className="listofcollaborators-container">
        <p className="title-page">Change Password</p>
        <div className="">
            <form onSubmit={(e) => handleSubmit(e)} className="resetpsw-container">
                <div className="resetpsw-form-group">
                    <label for="oldpsw">Old Password</label>
                    <input type="password" id="oldpsw" name="oldpsw" required className='resetpsw-form-input'></input>
                </div>
                <div className="resetpsw-form-group">
                    <label for="newpsw">New Password</label>
                    <input type="password" id="newpsw" name="newpsw" required className='resetpsw-form-input'></input>
                </div>
                <div className="resetpsw-form-group">
                    <label for="confirmpsw">Confirm New Password</label>
                    <input type="password" id="confirmpsw" name="confirmpsw" required className='resetpsw-form-input'></input>
                </div>
                {showNotification[0] ? <div className="resetpsw-form-group">
                    <Notification message={showNotification[1]} type={showNotification[2]} onClose={closeNotification} />
                </div>: null}
                {isLoading? <SpinLoader/> : (<div className="resetpsw-form-group">
                    <input type="submit" value="Submit" className="resetpsw-form-submitbtn"></input>
                </div>)}
            </form>
        </div>

    </div>);

}