import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import "bootstrap-icons/font/bootstrap-icons.css";
import CustomLink from "./link";
import { useUser } from '../context/UserContext';


const AdminSubPanel = (pathname) => {
  return (pathname.location.includes('/administration')?(<div>

    <CustomLink to="/administration/campaign" children={<div className="sidebar-menu-subitem"><p>Campagnes</p></div>}/>
    <CustomLink to="/administration/users" children={<div className="sidebar-menu-subitem"><p>Utilisateurs</p></div>}/>
    <CustomLink to="/administration/evaluated" children={<div className="sidebar-menu-subitem"><p>Evalués</p></div>}/>

  </div>)
  :<div></div>);
}


const Sidebar = () => {
  const user = useUser().user;
  const [isAuth, setIsAuth] = useState(false);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showLink, setShowLink] = useState(false);

  const handleClick = (value) => {
    setIsOpen(value!==undefined?value:!isOpen);
  }

  useEffect(() => {
    if (localStorage.getItem('access_token') !== null) {
      setIsAuth(true);
      if (user !== null) {
        console.log("sidebar roles: ", user.roles);
        if (user.roles.includes('ADMIN')){
          setIsAdmin(true);
          
        }
        if (localStorage.getItem('first_login') !== 'true'){
          setShowLink(true);
        }
    }
    }
  }, [user]);

  return (
    <div className="sidebar" style={isOpen?{left:'0rem'}:null}>
      <div className="sidebar-header">
        <img
          src="/logocosumar-1@2x.png"
          alt="logo"
          style={{ margin: 'auto', padding: '5px', display: 'flex', width: 250 }}
        />
      </div>
      {isAuth&&showLink ? (
        <div className="sidebar-menu">
          <CustomLink to="/listofcollaborators" children={<div className="sidebar-menu-item" onClick={()=>handleClick(false)} style={{
            marginBottom: '1rem', background: location.pathname === '/listofcollaborators' ? 'white' : 'transparent',
            color: location.pathname === '/listofcollaborators' ? '#20418e' : 'white'
          }}><p><i className="bi bi-person"></i> Liste des collaborateurs</p></div>} />
          <CustomLink to="/dictofskills" children={<div className="sidebar-menu-item" onClick={()=>handleClick(false)} style={{
            marginBottom: '1rem', background: location.pathname === '/dictofskills' ? 'white' : 'transparent',
            color: location.pathname === '/dictofskills' ? '#20418e' : 'white'
          }}><p><i className="bi bi-book"></i> Dictionnaire des compétences</p></div>} />
          {isAdmin ? (<div>
            <CustomLink to="/administration/campaign" children={<div className="sidebar-menu-item" onClick={()=>handleClick(false)} style={{
              marginBottom: '1rem', background: location.pathname === '/administration' ? 'white' : 'transparent',
              color: location.pathname === '/administration' ? '#20418e' : 'white'
            }}><p><i className="bi bi-gear"></i> Administration</p></div>} />
            <AdminSubPanel location={location.pathname}/>
          </div>) : null}
        </div>
      ) : (
        <div className="sidebar-menu"></div>
      )}
      <div className="sidebar-loginout-btn">
        {isAuth ? (
          <div>
            <CustomLink to="/logout" children={<div onClick={()=>handleClick(false)}><i className="bi bi-door-open" ></i> Logout</div>} />
          </div>
        ) : (
          <CustomLink to="/login" children={<div onClick={()=>handleClick(false)}><i className="bi bi-box-arrow-in-right"></i> Login</div>} />
        )}
      </div>
      <div className="sidebar-btn-opener" onClick={() => handleClick()}><i class="bi bi-list"></i></div>
    </div>
  );
};

export default Sidebar;
