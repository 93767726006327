import { redirect, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import Notification from './Notifications';
import ax from '../interceptors/axios';
import { useUser } from "../context/UserContext";
import {useQuery} from "@tanstack/react-query";

export const Login = () => {
  const navigate = useNavigate();
  const userContext = useUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showNotification, setShowNotification] = useState([false, '']);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading && localStorage.getItem('access_token') && localStorage.getItem('first_login') === false) {
        navigate('/listofcollaborators');
    }
    setIsLoading(false);
  }, [isLoading]);

  const submit = async e => {
    e.preventDefault();

    const user = {
      email: email,
      password: password
    };

    try {
      const { data } = await ax.post('token/', user, {
        headers: {
          'Content-Type': 'application/json'
        }
      }, { withCredentials: true });

      if (data !== undefined) {
        localStorage.clear();
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('refresh_token', data.refresh_token);
        localStorage.setItem('first_login', data.first_login);

        const userResponse = await ax.get(`users/${data.user_id}/`);
        const userData = userResponse.data;
        localStorage.setItem('connected_user', JSON.stringify(userData));
        userContext.setUser(localStorage.getItem('connected_user', {}));

        if (data.first_login === true) {
          window.location.href = '/resetpsw';
        } else {
          window.location.href = '/listofcollaborators';
        }
        
      } else {
        setShowNotification([true, 'Email or password is incorrect']);
      }
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 401) {
          setShowNotification([true, 'Email or password is incorrect']);
        } else {
          setShowNotification([true, 'An error occurred during authentication']);
        }
      }
  }

  const closeNotification = () => {
    setShowNotification([false, '']);
  };

  return (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={submit}>
        <div className="Auth-form-content">
          <center><img src="/cosumar-logo-alone.png" alt="logo" /></center>
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="form-group mt-3">
            <label>Email</label>
            <input
              className="form-control mt-1"
              placeholder="Enter Email"
              name='email'
              type='text'
              value={email}
              required
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              name='password'
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
              value={password}
              required
              onChange={e => setPassword(e.target.value)}
            />
          </div>

          <div className="d-grid gap-2 mt-3">

            <button type="submit" className="Auth-form-btn">
              Login
            </button>
          </div>
          {showNotification[0] && (
            <Notification
              message={showNotification[1]}
              type="error"
              onClose={closeNotification}
            />
          )}
        </div>

      </form>
    </div>

  )
}