import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ax, {root_api} from '../interceptors/axios';
import { useQuery } from "@tanstack/react-query";
import "bootstrap-icons/font/bootstrap-icons.css";

import {AdminUsers} from './AdminUsers';
import {AdminEvaluated} from './AdminEvaluated';
import {AdminCampaign} from './AdminCampaign';


export function Administration() {
  const location = useLocation();
  const [PageContent, setPageContent] = useState(null);
  const [title, setTitle] = useState('');
  
  useEffect(() => {
    if (location.pathname.includes("/users")){
        setPageContent(<AdminUsers/>);
        setTitle("des utilisateurs");
    } else if (location.pathname.includes("/evaluated")) {
      setPageContent(<AdminEvaluated/>);
      setTitle("des évalués");
    } else if (location.pathname.includes("/campaign")) {
      setPageContent(<AdminCampaign/>);
      setTitle("des campagnes");
    } else{
      setPageContent(null);
      setTitle('');
    }
  }, [location.pathname]);


  return (<div className="listofcollaborators-container">
    <p className="title-page">Administration {title}</p>
      {
        PageContent
      }

    </div>);

}