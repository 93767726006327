import {Link} from "react-router-dom";

const CustomLink = ({to, children}) => {


    const linkStyle = {
        textDecoration: 'none', // Remove underline
        color: 'inherit', // Use the default text color
      };
    return <Link to={to} style={linkStyle}>{children}</Link>
};
export default CustomLink;