import { useEffect, useState, useRef } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { SpinLoader } from "./spinLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import ax, {root_api} from '../interceptors/axios';
import { useQuery, useMutation } from "@tanstack/react-query";
import Notification from './Notifications';

const AddEvaluated = ({ setShowAddevaluated }) => {
    const [showNotification, setShowNotification] = useState([false, '', 'error']);

    const { isFetching, data: listPoste } = useQuery({
        enabled: true,
        queryKey: ['listofposte'],
        cacheTime: 24 * 60 * (60 * 1000),
        staleTime: 24 * 60 * (60 * 1000),
        queryFn: () => {
            return ax.get('position/').then(res => {
                console.log("POSTE FOR ADMIN USERS", res.data)
                return res.data
            })
        }
    })

    const saveUser = useMutation({
        mutationFn: (new_user) => {
            const formData = new FormData();
            Object.keys(new_user).forEach((key) => {
                if (key !== 'profile_picture') {
                    formData.append(key, new_user[key]);
                }
            });

            formData.append('file', new_user.profile_picture);
            

            return ax.post('evaluated/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            ).then(res => {
                console.log("NEW USER ADDED", res.data)
                if (res.data?.message === 'ok') {
                    setShowNotification([true, 'Evalué(e) ajouté avec succès', 'success']);
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                } else {
                    console.log("ERROR ADDING USER", res)
                    const _msg = !(res.data?.message)?res.response.data.detail:res.data.message;
                    if (_msg == undefined) {
                        _msg = res.data;
                    }
                    setShowNotification([true, _msg, 'error']);
                }
                return res.data
            })
                .catch(err => {
                    console.log("ERROR ADDING USER", err.response.data)
                    setShowNotification([true, err.response.data.message, 'error']);
                })
        }
    })

    const { isFetching: isEvaluateurFetching, data: listEvaluateur } = useQuery({
        enabled: true,
        queryKey: ['listofevaluateur'],
        cacheTime: 24 * 60 * (60 * 1000),
        staleTime: 24 * 60 * (60 * 1000),
        queryFn: () => {
            return ax.get('users/all/').then(res => {
                return res.data
            })
        }
    })

    const onSubmitAddEvaluated = (e) => {
        e.preventDefault();

        const new_user = {
            matricule: e.target.matricule.value,
            first_name: e.target.first_name.value,
            last_name: e.target.last_name.value,
            position: e.target.poste.value,
            evaluator: e.target.evaluator.value,
            date_integration: e.target.date_integration.value,
            profile_picture: e.target.profile_picture.files[0]??''
        }

        saveUser.mutate(new_user);
    };

    const onSetShowEvaluated = () => {
        setShowAddevaluated(false);
    }

    const closeNotification = () => {
        setShowNotification([false, '', 'error']);
    };

    return (<div className="admin-adduser-container">
        {!(isEvaluateurFetching && isFetching) ? <><div className="admin-adduser-title"><p>Ajouter un(e) évalué(e)</p><i class="bi bi-x-square-fill" onClick={() => onSetShowEvaluated()}></i></div>
            <form onSubmit={onSubmitAddEvaluated}>
                <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Matricule *</label>
                            <input type="input" placeholder='Matricule' required id="matricule"></input>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>First Name *</label>
                            <input type="input" placeholder='First Name' required id="first_name"></input>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Last Name *</label>
                            <input type="input" placeholder='Last Name' required id="last_name"></input>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Poste *</label>
                        <select type="option" required id="poste">
                            <option value="" style={{ backgroundColor: 'lightgray' }}>-- Poste</option>
                            {listPoste?.map((poste) => (
                                <option value={poste.id}>{poste.title.substring(0, 60) + ((poste.title.length > 60) ? '...' : '')}</option>
                            ))}
                        </select>
                        <label>Date d'intégration *</label>
                        <input type="date" required id="date_integration"></input>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Evaluateur *</label>
                        <select type="option" required id="evaluator">
                            <option value="" default={true} style={{ backgroundColor: 'lightgray' }} >-- Evaluateur</option>
                            {listEvaluateur?.map((evaluateur) => (
                                <option value={evaluateur.id}>{evaluateur.first_name + ' ' + evaluateur.last_name}</option>
                            ))}
                        </select>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <label>Profile picture (Optionel)</label>
                        <input type="file" id="profile_picture"></input>
                    </div>
                </div>
                {showNotification[0] ? <div className="resetpsw-form-group" style={{ marginTop: '1rem' }}>
                    <Notification message={showNotification[1]} type={showNotification[2]} onClose={closeNotification} />
                </div> : null}
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                    <button type="submit" className="adduser-btn-submit">Ajouter</button>
                </div>
            </form></> : <SpinLoader />}
    </div>);
}

const EditEvaluated = ({ setShowEditEvaluated, evaluatedId }) => {
    const [showNotification, setShowNotification] = useState([false, '', 'error']);
    const [isEvalFinished, setIsEvalFinished] = useState(false);

    const { isFetching, data: listPoste } = useQuery({
        enabled: true,
        queryKey: ['listofposte'],
        cacheTime: 24 * 60 * (60 * 1000),
        staleTime: 24 * 60 * (60 * 1000),
        queryFn: () => {
            return ax.get('position/').then(res => {
                return res.data
            })
        }
    })

    const { isFetching: isEvaluatedFetching, data: EvaluatedData } = useQuery({
        enabled: true,
        queryKey: ['evaluatedData', evaluatedId],
        queryFn: () => {
            return ax.get(`evaluated/${evaluatedId}/`).then(res => {
                console.log("evaluated FOR ADMIN: ", res.data)
                console.log("Evaluation is finished: ", res.data.isEvaluated);
                setIsEvalFinished(res.data.isEvaluated);
                return res.data;
            })
        }
    })

    const { isFetching: isEvaluateurFetching, data: listEvaluateur } = useQuery({
        enabled: true,
        queryKey: ['listofevaluateur'],
        cacheTime: 24 * 60 * (60 * 1000),
        staleTime: 24 * 60 * (60 * 1000),
        queryFn: () => {
            return ax.get('users/all/').then(res => {
                return res.data;
            })
        }
    })

    const updateUser = useMutation({
        mutationFn: (new_user) => {
            const formData = new FormData();
            Object.keys(new_user).forEach((key) => {
                if (key !== 'profile_picture') {
                    formData.append(key, new_user[key]);
                }
            });

            formData.append('file', new_user.profile_picture);

            return ax.patch(`evaluated/${evaluatedId}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            ).then(res => {
                if (res.data?.message === 'ok') {
                    setShowNotification([true, 'Evalué(e) modifié(e) avec succès', 'success']);
                    console.log("EVALUATED UPDATED", res)
                    setTimeout(() => {
                        console.log("RELOADING PAGE")
                        window.location.reload();
                    }, 500);
                } else {
                    console.log("ERROR UPDATING EVALUATED", res.response.data.detail)
                    const _msg = !(res.data?.message)?res.response.data.detail:res.data.message;
                    if (_msg == undefined) {
                        _msg = res.data;
                    }
                    setShowNotification([true, _msg, 'error']);
                    return ;
                }
                return res.data
            })
        }
    })

    const onSubmitEditEvaluated = (e) => {
        e.preventDefault();

        if (EvaluatedData.position.id == e.target.poste?.value && EvaluatedData.evaluator_id == e.target.evaluator?.value && !e.target.profile_picture.files[0] && EvaluatedData.isEvaluated == isEvalFinished){
            setShowNotification([true, "No changes have been made", 'warning']);
            return;
        }

        const update_user = {
            position: e.target.poste?.value,
            evaluator: e.target.evaluator?.value,
            profile_picture: e.target.profile_picture.files[0]??'',
            isEvaluated: isEvalFinished
        }

        updateUser.mutate(update_user);

    };

    const onShowEditevaluated = () => {
        setShowEditEvaluated(false);
    }

    const closeNotification = () => {
        setShowNotification([false, '', 'error']);
    };

    return (<div className="admin-adduser-container">
        {!(isEvaluatedFetching || isFetching || isEvaluateurFetching) ? <>
            <div className="admin-adduser-title"><img src={EvaluatedData.profile_picture.replace('static/public/', '')} alt="avatar" width="60rem" height="60rem" class="rounded-circle" style={{ margin: '5px' }} />
                <p>Editer {EvaluatedData.first_name + ' ' + EvaluatedData.last_name}</p><i class="bi bi-x-square-fill" onClick={() => onShowEditevaluated()}></i></div>
            <form onSubmit={onSubmitEditEvaluated}>
                <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Matricule</label>
                            <input type="input" placeholder='Matricule' value={EvaluatedData.matricule} disabled required></input>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>First Name</label>
                            <input type="input" placeholder='First Name' value={EvaluatedData.first_name} disabled required></input>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Last Name</label>
                            <input type="input" placeholder='Last Name' value={EvaluatedData.last_name} disabled required></input>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Poste</label>
                        <select type="option" required id="poste" disabled>
                            <option value="" default={EvaluatedData.position.id ? false : true} style={{ backgroundColor: 'lightgray' }} disabled>-- Selectionnez un poste</option>
                            {listPoste?.map((poste) => (
                                <option selected={EvaluatedData.position.id == poste.id} value={poste.id}>{poste.title.substring(0, 60) + ((poste.title.length > 60) ? '...' : '')}</option>
                            ))}
                        </select>
                        <label>Date d'intégration</label>
                        <input type="date" value={EvaluatedData.date_integration} id="date_integration" disabled></input>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Evaluateur</label>
                        <select type="option" required id="evaluator">
                            <option value="" default={EvaluatedData.evaluator_id ? false : true} style={{ backgroundColor: 'lightgray' }} disabled>-- Evaluateur</option>
                            {listEvaluateur?.filter((user) => user.matricule != EvaluatedData.matricule).map((evaluateur) => (
                                <option value={evaluateur.id} selected={evaluateur.id == EvaluatedData.evaluator_id}>{evaluateur.first_name + ' ' + evaluateur.last_name}</option>
                            ))}
                        </select>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <label>Profile picture (Optionel)</label>
                            <input type="file" id="profile_picture"></input>
                        </div>
                        
                        {EvaluatedData.isEvaluated?<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap:'0.5rem' }}>
                            <label>Status d'évaluation</label>
                            <input className="adminevaluated-edit-isevaluated-checkbox" type="checkbox" id="isEvaluated" checked={isEvalFinished} onChange={(e) => setIsEvalFinished(e.target.checked)}></input>
                        </div>:null}
                    </div>
                </div>
                {showNotification[0] ? <div className="resetpsw-form-group" style={{marginTop: '1rem'}}>
                    <Notification message={showNotification[1]} type={showNotification[2]} onClose={closeNotification} />
                </div>: null}
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                    <button type="submit" className="adduser-btn-submit">Valider</button>
                </div>
            </form></> : <SpinLoader />}
    </div>);
}


const DeleteEvaluated = ({ setShowDeleteEvaluated, evaluatedId }) => {
    const [showNotification, setShowNotification] = useState([false, '', 'error']);

    const { isFetching: isEvaluatedFetching, data: EvaluatedData } = useQuery({
        enabled: true,
        queryKey: ['evaluatedData', evaluatedId],
        queryFn: () => {
            return ax.get(`evaluated/${evaluatedId}/`).then(res => {
                console.log("evaluated FOR ADMIN on delete: ", res.data)
                console.log("Evaluation is finished: ", res.data.isEvaluated);
                return res.data;
            })
        }
    })

    const deleteUser = useMutation({
        mutationFn: () => {

            return ax.post(`evaluated/${evaluatedId}/delete/`)
            .then(res => {
                if (res.status === 204) {
                    setShowNotification([true, 'Evalué(e) supprimé(e) avec succès', 'success']);
                    console.log("EVALUATED DELETING", res)
                    setTimeout(() => {
                        console.log("RELOADING PAGE")
                        window.location.reload();
                    }, 500);
                } else {
                    console.log("ERROR DELETING EVALUATED", res.response.data.detail)
                    const _msg = !(res.data?.message)?res.response.data.detail:res.data.message;
                    if (_msg == undefined) {
                        _msg = res.data;
                    }
                    setShowNotification([true, _msg, 'error']);
                    return ;
                }
                return res.data
            })
        }
    })

    const onSubmitDeleteEvaluated = () => {
        deleteUser.mutate();
    };

    const onShowDeleteevaluated = () => {
        setShowDeleteEvaluated(false);
    }

    const closeNotification = () => {
        setShowNotification([false, '', 'error']);
    };

    return (<div className="admin-adduser-container">
            {isEvaluatedFetching ? <SpinLoader /> : <>
            <div className="admin-adduser-title"><img src={EvaluatedData.profile_picture.replace('static/public/', '')} alt="avatar" width="60rem" height="60rem" class="rounded-circle" style={{ margin: '5px' }} />
            <p>êtes-vous sûr de vouloir supprimer {EvaluatedData.first_name + ' ' + EvaluatedData.last_name} ?</p><i class="bi bi-x-square-fill" onClick={() => onShowDeleteevaluated()}></i></div>
            {showNotification[0] ? <div className="resetpsw-form-group" style={{marginTop: '1rem'}}>
                    <Notification message={showNotification[1]} type={showNotification[2]} onClose={closeNotification} />
                </div>: null}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                <button type="button" onClick={onSubmitDeleteEvaluated} className="adduser-btn-submit">Comfirmer</button>
            </div></>}
    </div>);
}



const Header = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [showAddevaluated, setShowAddevaluated] = useState(false);

    const onShowAddevaluated = () => {
        setShowAddevaluated(!showAddevaluated);
    }
    return (
        <>
            <div className="search-bar-container">
                <div className="search-bar-btn-ajouteruser" onClick={onShowAddevaluated}><p>Ajouter un(e) évalué(e)</p></div>
                <input type="search" placeholder="Recherche d'évalué" className="search-input" value={searchParams.get('searchQuery') ?? ''} onChange={(e) => {
                    if (!e.target.value) {
                        setSearchParams({
                            ...searchParams.delete('searchQuery'),
                            order: searchParams.get('order'),
                            orderdate: searchParams.get('orderdate'),
                            page: 1
                        })
                    } else {
                        setSearchParams({
                            searchQuery: e.target.value,
                            order: searchParams.get('order'),
                            orderdate: searchParams.get('orderdate'),
                            page: 1
                        })
                    }
                }} />
            </div>
            {showAddevaluated ? <AddEvaluated setShowAddevaluated={setShowAddevaluated} /> : null}
        </>
    )
}

const Body = ({ list }) => {
    const [showEditEvaluated, setShowEditEvaluated] = useState(false);
    const [selectedEvaluated, setSelectedEvaluated] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isFiltreEvaluated, setIsFiltreEvaluated] = useState(searchParams.get('order') === 'true' ? true : false);
    const [isFiltreDateInt, setIsFiltreDateInt] = useState(searchParams.get('orderdate') === 'true' ? true : false);
    const [showDeleteEvaluated, setShowDeleteEvaluated] = useState(false);


    const handleEvaluatedClick = (id) => {
        setSelectedEvaluated(id);
        setShowEditEvaluated(true);

    }

    useEffect(() => {
        setSearchParams({
            list: true,
            order: isFiltreEvaluated,
            orderdate: isFiltreDateInt,
            page: searchParams.get('page') ?? 1,
            searchQuery: searchParams.get('searchQuery') ?? ''
        })
    }, [isFiltreEvaluated, isFiltreDateInt])

    const handleClick = () => {
        setIsFiltreEvaluated(!isFiltreEvaluated)
    }
    const handleFiltreDateInt = () => {
        setIsFiltreDateInt(!isFiltreDateInt)
    }


    const handleDeleteClick = (e, id) => {
        setSelectedEvaluated(id);
        setShowDeleteEvaluated(true);
        e.stopPropagation();
    }

    return (<>
        <table className="listofcollaborators-table">

            <thead>
                <tr className="listofcollaborators-table-tr">
                    <th>Collaborateur</th>
                    <th>Date d'intégration<div className="listofcollaborators-table-filterbydate" onClick={()=>handleFiltreDateInt()} style={{cursor:'pointer'}}><i class="bi bi-arrow-down-up"></i></div></th>
                    <th>Statut<div className="listofcollaborators-table-filterbystatus" onClick={()=>handleClick()} style={{cursor:'pointer'}}><i class="bi bi-arrow-down-up"></i></div></th>
                    <th>Supprimer</th>
                </tr>
            </thead>
            <tbody>
                {
                    list.results?.map((evaluated, index) => (
                        <tr key={evaluated.id} onClick={() => handleEvaluatedClick(evaluated.id)} style={{ backgroundColor: index % 2 === 0 ? '#f4f6fa' : 'white', cursor: 'pointer' }} >
                            <td>
                                <div className="listofcollaborators-table-employee-container">
                                    <img src={evaluated.profile_picture.replace('static/public/', '')} alt="avatar" width="60rem" height="60rem" class="rounded-circle" style={{ margin: '5px' }} />
                                    <div className="listofcollaborators-table-employee-identity">
                                        <div className="listofcollaborators-table-employee-name">{evaluated.first_name + ' ' + evaluated.last_name}</div>
                                        <div className="listofcollaborators-table-employee-poste">{evaluated.position.intitule}</div>
                                    </div>
                                </div>
                            </td>
                            <td><div className="listofcollaborators-table-info">{evaluated.date_integration}</div></td>
                            <td><div className={evaluated.isEvaluated ? "listofcollaborators-table-isevaluated" : "listofcollaborators-table-notevaluated"}></div></td>
                            <td><div className="listofcollaborators-table-delete" onClick={(e)=>handleDeleteClick(e, evaluated.id)}><i class="bi bi-trash"></i></div></td>
                        </tr>
                    ))}
            </tbody>
        </table>
        {showEditEvaluated ? <EditEvaluated setShowEditEvaluated={setShowEditEvaluated} evaluatedId={selectedEvaluated} /> : null}
        {showDeleteEvaluated? <DeleteEvaluated setShowDeleteEvaluated={setShowDeleteEvaluated} evaluatedId={selectedEvaluated} />: null}
    </>
    )
}

const Footer = ({ pageCount }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLastPage, setIsLastPage] = useState(false)
    const [isFirstPage, setIsFirstPage] = useState(false)

    useEffect(() => {
        const page = Number(searchParams.get('page'))
        if (page) {
            setIsLastPage(Math.ceil(pageCount / 10) <= page)
            setIsFirstPage(page === 1)
        }
    }, [searchParams])


    return (
        <>
            <div className="listofcollaborators-pagehandler" >
                <div className="listofcollaborators-pagehandler-container">
                    <button type="button" className="listofcollaborators-pagehandler-btn" disabled={isFirstPage} onClick={() => {
                        setSearchParams({
                            searchQuery: searchParams.get('searchQuery')??'',
                            order: searchParams.get('order'),
                            orderdate: searchParams.get('orderdate'),
                            page: 1
                        })
                    }}><i className="bi bi-chevron-double-left"></i></button>
                    <button type="button" className="listofcollaborators-pagehandler-btn" disabled={isFirstPage} onClick={() => {
                        setSearchParams({
                            searchQuery: searchParams.get('searchQuery')??'',
                            order: searchParams.get('order'),
                            orderdate: searchParams.get('orderdate'),
                            page: Number(searchParams.get('page')) - 1
                        })
                    }}><i className="bi bi-chevron-left"></i></button>
                    <div className="listofcollaborators-pagehandler-page">{searchParams.get('page')}</div>
                    <button type="button" className="listofcollaborators-pagehandler-btn" disabled={isLastPage} onClick={() => {
                        setSearchParams({
                            searchQuery: searchParams.get('searchQuery')??'',
                            order: searchParams.get('order'),
                            orderdate: searchParams.get('orderdate'),
                            page: Number(searchParams.get('page')) + 1
                        })
                    }}><i className="bi bi-chevron-right"></i></button>
                    <button type="button" className="listofcollaborators-pagehandler-btn" disabled={isLastPage} onClick={() => {
                        setSearchParams({
                            searchQuery: searchParams.get('searchQuery')??'',
                            order: searchParams.get('order'),
                            orderdate: searchParams.get('orderdate'),
                            page: Math.ceil(pageCount / 10)
                        })
                    }}><i className="bi bi-chevron-double-right"></i></button>
                    {(Math.ceil(pageCount / 10) == 1)?'':<div className="listofcollaborators-pagehandler-page" style={{backgroundColor:'#f0f0f0', color: '#20418e'}}>{Math.ceil(pageCount / 10)}</div>}
                </div>
            </div >

        </>
    )
}



export const AdminEvaluated = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { isFetching, data: list, isPreviousData, refetch } = useQuery({
        enabled: true,
        queryKey: ['listofadminevaluated', searchParams?.get('page'), searchParams?.get('searchQuery'), searchParams.get('orderdate'), searchParams?.get('order')],
        cacheTime: 24 * 60 * (60 * 1000),
        staleTime: 24 * 60 * (60 * 1000),
        queryFn: () => {
            return ax.get('evaluated/owned/', {
                params: {
                    list: true,
                    order: searchParams.get('order') ?? false,
                    orderdate: searchParams.get('orderdate') ?? false,
                    search: (searchParams.get('searchQuery')?.length > 2) ? searchParams.get('searchQuery') : '',
                    page: searchParams?.get('page') ?? 1,
                }
            }).then(res => {
                console.log("DATA FOR ADMIN EVALUATED", res.data)
                return res.data
            })
        }
    })


    useEffect(() => {
        if (!searchParams.get('page')) {
            setSearchParams({
                page: 1
            })
        }
        refetch();
    }, [])

    return (
        <div className="listofcollaborators-container">
            <Header />
            {isFetching ? <SpinLoader /> : !list?.results?.length ?
                <div className="listofcollaborators-content">
                    <div className="listofcollaborators-card" style={{ backgroundColor: 'white' }}>
                        <div className="listofcollaborators-card-content">
                            <div className="listofcollaborators-card-container">
                                <p className="card-title">Aucun collaborateur trouvé.</p>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <>
                    <Body list={list} />
                    <Footer pageCount={list?.count} />
                </>
            }
        </div>
    );
};
