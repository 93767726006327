import React, { useState, useEffect, useRef } from 'react';
import { useUser } from '../context/UserContext';
import "bootstrap-icons/font/bootstrap-icons.css";
import { useNavigate } from 'react-router-dom';
import {root_api} from '../interceptors/axios';

const DropDown = ({setIsOpen, isOpen, parentRef}) => {
  const ref = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      console.log(!ref.current.contains(event.target), !parentRef.current.contains(event.target))
      if ((ref.current && !ref.current.contains(event.target)) && !parentRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [ref]);

  return <div className="nav-dropdown" aria-labelledby="dropdownMenuButton1" ref={ref}> 
      <div onClick={()=>{navigate("/resetpsw")}}>Change password</div>
      <div onClick={()=>{navigate("/logout")}}>Logout</div>
    </div>
}


export function Navigation() {
  const user = useUser().user;
  const [isAuth, setIsAuth] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (user !== null) {
      console.log("set auth to true")
      setIsAuth(true);
    }
  }, [user]);

  if (!isAuth) { return <div></div> }

  const handleDropDown = () => {
    setIsOpen(!isOpen);
  }

  return <header className="p-3 mb-3">
    <div className="nav-welcome-container">
      <div className="nav-welcome-text">
        <p className='nav-welcome-text-welcome'>Bienvenue</p>
        <p className='nav-welcome-text-fullname'>{user.first_name + ' ' + user.last_name}</p>
      </div>
      <div className="nav-image-container" onClick={()=>handleDropDown()} ref={ref}>
        <img src={user.profile_picture.replace('static/public/', '')} width="32" height="32" className="rounded-circle" />
        <i class={isOpen ? "bi bi-arrow-down-short": "bi bi-arrow-up-short"} style={{color: '#20418e'}}></i>
      </div>
      {isOpen ? <DropDown setIsOpen={setIsOpen} isOpen={isOpen} parentRef={ref}/>: ''}
    </div>
  </header>;

}