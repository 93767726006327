import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import { SpinLoader } from "./spinLoader";
import ax, {root_api} from '../interceptors/axios';
import { useQuery } from "@tanstack/react-query";
import Notification from './Notifications';

const EmojiRating = ({ levelRequired, levelAcquired }) => {

    const [emoji, setEmoji] = useState(null);

    useEffect(() => {
        if (levelAcquired === -1 || levelAcquired === undefined) {
            setEmoji('');
        } else if (levelAcquired === 0) {
            setEmoji(<div>Non Acquis</div>);
        } else if (levelAcquired == levelRequired - 1) {
            setEmoji(<img src="/emoji_red_sad.png" />);
        } else if (levelAcquired < levelRequired) {
            setEmoji(<img src="/emoji_red_sad.png" />);
        } else if (levelAcquired == levelRequired) {
            setEmoji(<img src="/emoji_green_smile.png" />);
        } else if (levelAcquired > levelRequired) {
            setEmoji(<img src="/emoji_green_big.png" />);
        }
    }, [levelAcquired]);

    return (
        <>
            <div className="evaluated-emoji-container">{emoji}</div>
        </>
    );
};


const StarRating = ({ evaluatedResultSkill, onRate, canModify }) => {
    const [rating, setRating] = useState(null);

    const handleRating = (value) => {

        setRating(value);
        onRate(evaluatedResultSkill.skill, value);
    };

    return (
        <>
            {[4, 3, 2, 1, 0].map((value) => (
                <>
                    <input
                        className={`radio-input${value==0?'-na':''}`}
                        type="radio"
                        id={`${evaluatedResultSkill.skill}-star${value}`}
                        name={`${evaluatedResultSkill.skill}-star-input`}
                        value={value}
                        checked={value === evaluatedResultSkill.level}
                        onChange={() => handleRating(value)}
                        required={true}
                        disabled={!canModify}
                    />
                    <label
                        className={`radio-label${value==0?'-na':''}`}
                        htmlFor={`${evaluatedResultSkill.skill}-star${value}`}
                        title={`${value} stars`}
                    >
                        ★
                    </label>
                </>
            ))}
        </>
    );
};

const CommentairePopUp = ({ setIsCommentOpen, evaluatedResult, setEvaluatedResult, commentId, hidden,
    isValidateComment, setFinalComment, finalComment, evaluatedName,
    setIsValidateComment, evaluationDone}) => {
    const navigate = useNavigate();
    const [comment, setComment] = useState('');
    const dialogRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        if (isValidateComment) {
            setComment(finalComment);
        } else {
            if (evaluatedResult[commentId]?.comment !== '') {
                setComment(evaluatedResult[commentId]?.comment);
            };
        }
    }, []);

    useEffect(() => {
        if (hidden) {
            dialogRef?.current?.close();

        } else {
            dialogRef?.current?.showModal();
            console.log("FINAL COMMENT:", evaluatedResult);
            if (evaluatedResult[commentId]?.comment && !isValidateComment){setComment(evaluatedResult[commentId]?.comment);}
            else if (isValidateComment){setComment(finalComment);}
        }
    }, [hidden])

    const closeDialog = () => {
        setIsCommentOpen(false)
        setComment('');
        setIsValidateComment(false);
    }


    const validComment = (e) => {
        if (isValidateComment) {
            setFinalComment(comment);
        } else {
            const _temp = [...evaluatedResult];
            _temp[commentId].comment = comment;
            setEvaluatedResult(_temp);
        }
        setComment('');
        if (!isValidateComment)
            setIsCommentOpen(false);
    }


    const handleGoBack = () => {
        if (location.state === undefined || location.state === null) {
            window.location.href = '/listofcollaborators';
        } else {
            const _query = location.state['query']?.length > 0 ? `searchQuery=${location.state['query']}&` : '';
            window.location.href = `/listofcollaborators?${_query}page=${location.state['page']}&order=${location.state['order']}&refresh`;
        }
    }


    return (
        <dialog ref={dialogRef} >
            <div className="evaluated-commentaire-input-container">
                {!evaluationDone ? <>
                    <p>Commentaire (Optionnel)</p>
                    {isValidateComment && <div className="evaluated-commentaire-input-text">Avant de valider, vous avez la possibilité d'ajouter un commentaire pour <b>{evaluatedName}</b> ou de proposer une compétence qu'elle/il possède.</div>}
                    <textarea type="text" className="evaluated-commentaire-input-field" placeholder="Vote commentaire ici..." value={comment} onChange={(e) => { setComment(e.target.value) }}></textarea>
                    <div className="evaluated-commentaire-input-footer"><button type="button" className="" style={{ backgroundColor: '#E9EEF8', margin: '5px' }} onClick={() => closeDialog()}>Annuler</button>
                        <button type={(isValidateComment) ? "submit" : "button"} style={{ backgroundColor: '#F5C84D', margin: '5px' }} onClick={(e) => validComment(e)}>Valider</button>
                    </div></> : <div className="evaluated-commmentaire-final">
                    <i class="bi bi-clipboard-check" style={{ fontSize: '8rem', color: '#FFD401' }}></i>
                    <p>Félicitations !</p>
                    <div className="evaluated-commentaire-input-text" style={{ textAlign: 'center' }}>L’appréciation des compétences de <b>{evaluatedName}</b> a été effectuée avec succès</div>
                    <button type="button" className="evaluated-commentaire-btn-final" style={{ backgroundColor: '#F5C84D', margin: '5px' }} onClick={() => handleGoBack()}>Évaluer un autre collaborateur</button>
                </div>}
            </div>
        </dialog>
    )
};

const MetadataPopUp = ({ skill }) => {
    return (
        <div className="evaluated-metadata-container">
            <div className="evaluated-skill-info-metadata" style={{ fontWeight: '700', fontSize: '1rem' }}>Compétence<br></br>{skill.title}</div><div style={{ fontWeight: '500' }}>Définition</div><div style={{ fontWeight: '400', fontSize: '0.7rem', marginBottom: '1rem' }}>{skill.description}</div>
            <div className="evaluated-skill-info-metadata-container">
                <div className="evaluated-skill-info-metadata-descrition">
                    <div style={{ fontWeight: '500' }}><img src="/emojiLvl1.png"
                        className="dictofskill-content-category-list-item-lvlicon"></img>Débutant (1)</div>
                    <div className="evaluated-skill-metadata-text" style={{whiteSpace: "pre-wrap"}}>{skill.metadata.level_1}</div>
                </div>
                <div className="evaluated-skill-info-metadata-descrition">
                    <div style={{ fontWeight: '500' }}><img src="/emojiLvl2.png"
                        className="dictofskill-content-category-list-item-lvlicon" style={{ marginLeft: '-0.5rem' }}></img>Qualifié (2)</div>
                    <div className="evaluated-skill-metadata-text" style={{whiteSpace: "pre-wrap"}}>{skill.metadata.level_2}</div>
                </div>
                <div className="evaluated-skill-info-metadata-descrition">
                    <div style={{ fontWeight: '500' }}><img src="/emojiLvl3.png"
                        className="dictofskill-content-category-list-item-lvlicon" style={{ marginLeft: '-0.5rem' }}></img>Confirmé (3)</div>
                    <div className="evaluated-skill-metadata-text" style={{whiteSpace: "pre-wrap"}}>{skill.metadata.level_3}</div>
                </div>
                <div className="evaluated-skill-info-metadata-descrition">
                    <div style={{ fontWeight: '500' }}><img src="/emojiLvl4.png"
                        className="dictofskill-content-category-list-item-lvlicon"></img>Expert (4)</div>
                    <div className="evaluated-skill-metadata-text" style={{whiteSpace: "pre-wrap"}}>{skill.metadata.level_4}</div>
                </div>
            </div>

        </div>
    )
};

export const Evaluated = () => {
    const { id: evaluated_id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [evaluatedResult, setEvaluatedResult] = useState([]);
    const [isCommentOpen, setIsCommentOpen] = useState(false);
    const [commentId, setCommentId] = useState(null);
    const [isValidateComment, setIsValidateComment] = useState(false);
    const [finalComment, setFinalComment] = useState(null);
    const [evaluationDone, setEvaluationDone] = useState(false);
    const [canModify, setCanModify] = useState(false);
    const [allFieldFilled, setAllFieldFilled] = useState(false);
    const [showNotification, setShowNotification] = useState([false, '', 'error']);
    const [showStarLegend, setShowStarLegend] = useState(false);

    const { isFetching, data: evaluated, refetch } = useQuery({
        enabled: true,
        cacheTime: 24 * 60 * (60 * 1000),
        staleTime: 24 * 60 * (60 * 1000),
        queryKey: ['evaluatedData', evaluated_id],
        queryFn: () => {
            return ax.get(`evaluated/${evaluated_id}/data/`)
                .then(res => {
                    console.log("EVALUATED DATA: ", res);
                    const _buildingEvaluatedResult = [];
                    if (res.data[0].evaluations === null || res.data[0].evaluations === undefined) {
                        for (let i = 0; i < res.data[0].skills.length; i++) {
                            _buildingEvaluatedResult.push({ skill: res.data[0].skills[i].skill.id, level: -1, comment: '' });
                        }
                        setCanModify(true);
                    } else {
                        for (let i = 0; i < res.data[0].evaluations.evaluatedResults.length; i++) {
                            if (res.data[0].evaluations.evaluatedResults[i].levelAcquired === -1) {
                                setCanModify(true);
                            };
                            _buildingEvaluatedResult.push({
                                skill: res.data[0].evaluations.evaluatedResults[i].skill_id,
                                level: res.data[0].evaluations.evaluatedResults[i].levelAcquired,
                                comment: res.data[0].evaluations.evaluatedResults[i].comment
                            });
                        }
                    }
                    if (res.data[0].isEvaluated === false) {
                        setCanModify(true);
                    }
                    setEvaluatedResult(_buildingEvaluatedResult);
                    return res.data[0];
                })
        }
    })

    useEffect(() => {
        setAllFieldFilled(
            !evaluatedResult.some((element) => element.level == -1)
        );
    }
        , [evaluatedResult]);

    useEffect(() => {
        if (finalComment !== null) {
            submitEvaluation();
        } else {
            refetch();
        }
    }, [finalComment]);

    const submitEvaluation = () => {
        const _dataToSend = {
            "evaluatedResults": evaluatedResult,
            "finalComment": finalComment?.length > 0 ? finalComment : ''
        }
        try {
            ax.post(`evaluated/${evaluated_id}/evaluate/`, _dataToSend, {
            }).then(res => {
                if (allFieldFilled) {
                    setEvaluationDone(true);
                    setShowNotification([true, 'L’appréciation des compétences a été effectuée avec succès', 'success']);
                } else {
                    setShowNotification([true, 'L’appréciation des compétences a été sauvegardé avec succès', 'success']);
                }
            }).catch(error => {
                console.log("Error Saving evaluated's results data:", error)
                const _msg = !(error.data?.message) ? error.response.data.detail : error.data.message;
                if (_msg == undefined) {
                    _msg = error.data;
                }
                setShowNotification([true, _msg, 'error']);
            });
        } catch (error) {
            console.error("Error Saving evaluated's results data:", error);
        }
    }

    const displayComment = (id) => {
        setCommentId(id);
        setIsCommentOpen(true);
    }

    const onValidateForm = () => {
        if (allFieldFilled) {
            setIsValidateComment(true);
            setIsCommentOpen(true);
        } else {
            submitEvaluation();
        }
    }

    const closeNotification = () => {
        setShowNotification([false, '', 'error']);
    };

    const onRate = (skillId, value) => {
        const _temp = [...evaluatedResult];
        const index = _temp.findIndex((element) => element.skill === skillId);
        _temp[index].level = value;
        setEvaluatedResult(_temp);
    }

    const isFormFieldDone = () => {
        return !canModify;
    }

    const goBack = () => {
        if (location.state === undefined || location.state === null) {
            window.location.href = '/listofcollaborators';
        } else {
            const _query = location.state['query']?.length > 0 ? `searchQuery=${location.state['query']}&` : '';
            window.location.href = `/listofcollaborators?${_query}page=${location.state['page']}&order=${location.state['order']}&refresh`;
        }
    }


    return <div className="listofcollaborators-container">
        <p className="title-page">Campagne d'appréciation<br></br>des compétences</p>

        <div className="evaluated-content">
            {isFetching ? <SpinLoader /> : <div className="evaluated-content-header">
                <img src={`${evaluated.profile_picture.replace('static/public/', '')}`} alt="avatar" width="64" height="64" class="rounded-circle" style={{ margin: '5px' }} />
                <div className="listofcollaborators-table-employee-identity">
                    <div className="listofcollaborators-table-employee-name">{evaluated.first_name + ' ' + evaluated.last_name}</div>
                    <div className="listofcollaborators-table-employee-poste">{evaluated.position.intitule}</div>
                </div>
                <div className="evaluated-integrationdate">
                    <div className="listofcollaborators-table-employee-name" style={{marginLeft:"45%"}}><i class="bi bi-calendar4-week" ></i></div>
                    <div className="listofcollaborators-table-employee-name">{evaluated.date_integration}</div>
                </div>
            </div>
            }
            <div className="evaluated-content-body">
                {(isFetching || evaluatedResult.length == 0) ? <div></div> :
                    <form className="evaluated-result-form" onSubmit={submitEvaluation}>
                        <table className="evaluated-table">

                            <thead>
                                <tr>
                                    <th>Liste des compétences</th>
                                    <th>Niveau Requis</th>
                                    <th>Niveau acquis <i class="bi bi-info-circle" onMouseOver={()=>setShowStarLegend(true)} onMouseOut={()=>setShowStarLegend(false)}></i> </th>
                                    <th>Statut de C</th>
                                    <th>Commentaire</th>
                                </tr>
                            </thead>
                            <tbody>

                                {evaluated.skills.map((skill, index) => (
                                    <tr key={skill.id} style={{ borderRadius: '60%', backgroundColor: index % 2 === 0 ? '#f4f6fa' : 'white' }}>
                                        <td><div className="evaluated-skill-info">
                                            <div className="evaluated-skill-title">{skill.skill.title}</div>
                                            <div className="evaluated-skill-icon-info">
                                                <i class="bi bi-info-circle"></i>
                                                <MetadataPopUp skill={skill.skill} />
                                            </div>
                                        </div>
                                        </td>
                                        <td><center><div className="evaluated-lvl-required">{skill.levelRequired}</div></center></td>
                                        <td><div className="evaluated-stars-container">
                                            <StarRating evaluatedResultSkill={evaluatedResult[index]} onRate={onRate} canModify={canModify} />
                                        </div>
                                        </td>
                                        <td><EmojiRating levelRequired={skill.levelRequired} levelAcquired={evaluatedResult[index].level} /></td>
                                        <td><div className="evaluated-td-commentaire">{evaluatedResult[index].comment && <div className="evaluated-commentaire" style={{ backgroundColor: index % 2 !== 0 ? '#f4f6fa' : 'white' }}>
                                            {evaluatedResult[index].comment.substring(0, 20) + '...'}
                                        </div>}
                                            <button id={index} type="button" className="evaluated-commentaire-button" onClick={() => displayComment(index)} disabled={isFormFieldDone()}><i class="bi bi-pencil-square"></i></button>
                                        </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {!canModify? <div><p>Commentaire:</p> <p style={{fontSize:'1rem'}}>{evaluated.evaluations.finalComment.length?evaluated.evaluations.finalComment:"Aucun commentaire."}</p></div> : <div></div>}
                        {showNotification[0] ? <div className="resetpsw-form-group" style={{ marginTop: '1rem' }}>
                            <Notification message={showNotification[1]} type={showNotification[2]} onClose={closeNotification} />
                        </div> : null}
                        {(evaluated.skills === null || evaluated.skills?.lenght === 0) ? <div></div> : <div className="evaluated-content-footer">
                            <button type="button" className="" style={{ backgroundColor: '#E9EEF8', margin: '5px' }} onClick={goBack}>{canModify ? 'Annuler' : 'Retour'}</button>
                            {!canModify ? <div></div> : <button type="button" className="evaluated-validation-btn" style={{ backgroundColor: '#F5C84D', margin: '5px' }} onClick={() => onValidateForm()} disabled={isFormFieldDone()}>{allFieldFilled ? 'Valider' : 'Sauvegarder'}</button>}
                        </div>
                        }
                    </form>
                }
                <CommentairePopUp hidden={!isCommentOpen} setIsCommentOpen={setIsCommentOpen} evaluatedResult={evaluatedResult}
                    setEvaluatedResult={setEvaluatedResult} commentId={commentId} isValidateComment={isValidateComment} setIsValidateComment={setIsValidateComment}
                    setFinalComment={setFinalComment} finalComment={evaluated?.evaluations?.finalComment?evaluated?.evaluations?.finalComment:''} evaluatedName={evaluated?.first_name + ' ' + evaluated?.last_name}
                    evaluationDone={evaluationDone} />
                
                {showStarLegend && <div className="evaluated-legende-message-container">
                    <div className="evaluated-commentaire-input-text"><span style={{color:'gold', fontSize: '28px', textAlign: 'center'}}>★</span>: Niveaux d'acquisition</div>
                    <div className="evaluated-commentaire-input-text" style={{ textAlign: 'center'}}>✖️: Non Acquis</div>
                </div>}
            </div>
        </div>
    </div>
}