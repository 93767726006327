import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Login } from "./component/login";
import { ListOfCollaborators } from './component/listofcollaborators';
import { DictOfSkills } from './component/dictofskills';
import { Navigation } from './component/navigation';
import { Logout } from './component/logout';
import Sidebar from './component/sidebar';
import BackToTopButton from './component/backToTopButton';
import { Evaluated } from './component/evaluated';
import { ProtectedRoute } from './component/protectedRoute';
import { Page404 } from './component/Page404';
import { Administration } from './component/administration';
import {UserProvider} from './context/UserContext';
import {ResetPsw} from './component/resetpsw';
import { useEffect } from 'react';

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  },
});

function App() {
  // get current navigation url
  const currentUrl = window.location.pathname;

  useEffect(() => {
    document.title = "COSUMAR - CDAC";
  }, []);

  return (
    <BrowserRouter>
      <div className="myapp-container">
        {<UserProvider children={<Sidebar />}/>}
        <QueryClientProvider client={queryClient}>
          <div className="main-content">
            {<UserProvider children={<Navigation />}/>}
            <Routes>
              <Route path="*" element={<Page404/>} />
              <Route path="/login" element={<UserProvider children={<Login />} />} />
              <Route path="/logout" element={<UserProvider children={<Logout />} />} />
              <Route path="/" element={<UserProvider children={<Login />} />} />
              <Route path="/listofcollaborators" element={<ProtectedRoute><ListOfCollaborators /></ProtectedRoute>} />
              <Route path="/dictofskills" element={<ProtectedRoute><DictOfSkills /></ProtectedRoute>} />
              <Route path="/evaluated/:id" element={<ProtectedRoute><Evaluated /></ProtectedRoute>} />
              <Route path="/resetpsw" element={<UserProvider children={<ProtectedRoute><ResetPsw /></ProtectedRoute>} />} />
              <Route path="/administration/users" element={<UserProvider children={<ProtectedRoute isAdmin={true}><Administration /></ProtectedRoute>} />} />
              <Route path="/administration/campaign" element={<UserProvider children={<ProtectedRoute isAdmin={true}><Administration /></ProtectedRoute>} />} />
              <Route path="/administration/evaluated" element={<UserProvider children={<ProtectedRoute isAdmin={true}><Administration /></ProtectedRoute>} />} />
            </Routes>
            <BackToTopButton />
          </div>
        </QueryClientProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;