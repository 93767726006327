import { useEffect, useState, useRef } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { SpinLoader } from "./spinLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import ax, {root_api} from '../interceptors/axios';
import { useQuery, useMutation } from "@tanstack/react-query";
import Notification from './Notifications';

const AddUser = ({ setShowAdduser }) => {
    const [showNotification, setShowNotification] = useState([false, '', 'error']);

    const saveUser = useMutation({
        mutationFn: (new_user) => {
            const formData = new FormData();
            Object.keys(new_user).forEach((key) => {
                if (key !== 'profile_picture') {
                    formData.append(key, new_user[key]);
                }
            });

            formData.append('file', new_user.profile_picture);

            return ax.post('users/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                console.log("NEW USER ADDED", res.data)
                if (res.data?.message === 'ok') {
                    setShowNotification([true, 'Utilisateur ajouté avec succès', 'success']);
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                } else {
                    console.log("ERROR ADDING USER", res);
                    const _msg = !(res.data?.message) ? res.response.data.detail : res.data.message;
                    if (_msg == undefined) {
                        _msg = res.data;
                    }
                    setShowNotification([true, _msg, 'error']);
                }
                return res.data
            })
                .catch(err => {
                    console.log("CATCH ERROR ADDING USER", err.response.data)
                    setShowNotification([true, err.response.data.message, 'error']);
                })
        }
    })

    const { isFetching, data: listPoste } = useQuery({
        enabled: true,
        queryKey: ['listofposte'],
        cacheTime: 24 * 60 * (60 * 1000),
        staleTime: 24 * 60 * (60 * 1000),
        queryFn: () => {
            return ax.get('position/').then(res => {
                console.log("POSTE FOR ADMIN USERS", res.data)
                return res.data
            })
        }
    })

    const onSubmitAddUser = (e) => {
        e.preventDefault();

        if (e.target.confirm_password.value !== e.target.password.value) {
            setShowNotification([true, "Password and confirm password are not the same", 'error']);
            return;
        }
        if (!e.target.email.value.includes('@cosumar.ma')) {
            setShowNotification([true, "Email should be a valid cosumar email (@cosumar.ma)", 'error']);
            return;
        }

        const new_user = {
            matricule: e.target.matricule.value,
            email: e.target.email.value,
            first_name: e.target.first_name.value,
            last_name: e.target.last_name.value,
            position: e.target.poste.value,
            date_integration: e.target.date_integration.value,
            password: e.target.password.value,
            confirm_password: e.target.confirm_password.value,
            profile_picture: e.target.profile_picture.files[0] ?? ''
        }

        saveUser.mutate(new_user);
    };

    const onShowAdduser = () => {
        setShowAdduser(false);
    }

    const closeNotification = () => {
        setShowNotification([false, '', 'error']);
    };

    return (<div className="admin-adduser-container">
        {!(isFetching) ? <><div className="admin-adduser-title"><p>Ajouter un utilisateur</p><i class="bi bi-x-square-fill" onClick={() => onShowAdduser()}></i></div>
            <form onSubmit={onSubmitAddUser}>
                <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Matricule *</label>
                            <input type="input" placeholder='Matricule' required id="matricule"></input>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Email *</label>
                            <input type="input" placeholder='example@cosumar.ma' required id="email"></input>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>First Name *</label>
                            <input type="input" placeholder='First Name' required id="first_name"></input>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Last Name *</label>
                            <input type="input" placeholder='Last Name' required id="last_name"></input>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Poste *</label>
                        <select type="option" required id="poste">
                            <option value="" default style={{ backgroundColor: 'lightgray' }}>-- Poste</option>
                            {listPoste?.map((poste) => (
                                <option value={poste.id}>{poste.title.substring(0, 60) + ((poste.title.length > 60) ? '...' : '')}</option>
                            ))}
                        </select>
                        <label>Date d'integration *</label>
                        <input type="date" required id="date_integration"></input>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <label>Profile picture (Optionel)</label>
                        <input type="file" id="profile_picture"></input>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Password *</label>
                            <input type="password" placeholder='password' required id="password"></input>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Confirm Password *</label>
                            <input type="password" placeholder='confirm password' required id="confirm_password"></input>
                        </div>
                    </div>
                </div>
                {showNotification[0] ? <div className="resetpsw-form-group" style={{ marginTop: '1rem' }}>
                    <Notification message={showNotification[1]} type={showNotification[2]} onClose={closeNotification} />
                </div> : null}
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                    <button type="submit" className="adduser-btn-submit">Ajouter</button>
                </div>
            </form></> : <SpinLoader />}
    </div>);
}

const EditUser = ({ setShowEditUser, userId }) => {
    const [showNotification, setShowNotification] = useState([false, '', 'error']);

    const { isFetching, data: listPoste } = useQuery({
        enabled: true,
        queryKey: ['listofposte'],
        cacheTime: 24 * 60 * (60 * 1000),
        staleTime: 24 * 60 * (60 * 1000),
        queryFn: () => {
            return ax.get('position/').then(res => {
                return res.data
            })
        }
    })

    const { isFetching: isUserFetching, data: UserData } = useQuery({
        enabled: true,
        queryKey: ['userData', userId],
        queryFn: () => {
            return ax.get(`users/${userId}/`).then(res => {
                return res.data
            })
        }
    })

    const updateUser = useMutation({
        mutationFn: (new_user) => {
            const formData = new FormData();
            Object.keys(new_user).forEach((key) => {
                if (key !== 'profile_picture') {
                    formData.append(key, new_user[key]);
                }
            });

            formData.append('file', new_user.profile_picture);

            return ax.patch(`users/${userId}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                if (res.data?.message === 'ok') {
                    setShowNotification([true, 'Utilisateur modifié avec succès', 'success']);
                    console.log("USER UPDATED", res)
                    setTimeout(() => {
                        console.log("RELOADING PAGE")
                        window.location.reload();
                    }, 500);
                } else {
                    console.log("ERROR UPDATING USER", res)
                    const _msg = !(res.data?.message) ? res.response.data.detail : res.data.message;
                    if (_msg == undefined) {
                        _msg = res.data;
                    }
                    setShowNotification([true, _msg, 'error']);
                    return;
                }
                return res.data
            })
        }
    })

    const onSubmitEditUser = (e) => {
        e.preventDefault();

        if (e.target.password?.value && e.target.confirm_password?.value) {
            if (e.target.confirm_password.value !== e.target.password.value) {
                setShowNotification([true, "Password and confirm password are not the same", 'error']);
                return;
            }
        }

        if (UserData?.position?.id == e.target.poste?.value && !e.target.password?.value && !e.target.profile_picture?.files[0]) {
            setShowNotification([true, "No changes have been made", 'warning']);
            return;
        }

        const update_user = {
            position: e.target.poste.value,
            profile_picture: e.target.profile_picture.files[0] ?? '',
            password: e.target.password.value
        }

        updateUser.mutate(update_user);
    };

    const onShowEdituser = () => {
        setShowEditUser(false);
    }

    const closeNotification = () => {
        setShowNotification([false, '', 'error']);
    };

    return (<div className="admin-adduser-container">
        {!(isUserFetching || isFetching) ? <><div className="admin-adduser-title"><img src={UserData.profile_picture.replace('static/public/', '')} alt="avatar" width="60rem" height="60rem" class="rounded-circle" style={{ margin: '5px' }} />
            <p>Editer {UserData.first_name + ' ' + UserData.last_name}</p><i class="bi bi-x-square-fill" onClick={() => onShowEdituser()}></i></div>
            <form onSubmit={onSubmitEditUser}>
                <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Matricule</label>
                            <input type="input" placeholder='Matricule' value={UserData.matricule} disabled required></input>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Email</label>
                            <input type="input" placeholder='example@cosumar.ma' value={UserData.email} disabled required></input>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>First Name</label>
                            <input type="input" placeholder='First Name' value={UserData.first_name} disabled required></input>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Last Name</label>
                            <input type="input" placeholder='Last Name' value={UserData.last_name} disabled required></input>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Poste</label>
                        <select type="option" required id="poste" defaultValue={UserData?.position?.id} disabled>
                            <option value="" default={UserData.position?.id ? false : true} style={{ backgroundColor: 'lightgray' }} disabled>-- Selectionnez un poste</option>
                            {listPoste?.map((poste) => (
                                <option value={poste.id}>{poste.title.substring(0, 60) + ((poste.title.length > 60) ? '...' : '')}</option>
                            ))}
                        </select>
                        <label>Date d'integration</label>
                        <input type="date" value={UserData.date_integration} disabled required></input>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <label>Profile picture (Optionel)</label>
                        <input type="file" id="profile_picture"></input>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Change Password</label>
                            <input type="password" placeholder='password' id="password"></input>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Confirm new password</label>
                            <input type="password" placeholder='confirm password' id="confirm_password"></input>
                        </div>
                    </div>
                </div>
                {showNotification[0] ? <div className="resetpsw-form-group" style={{ marginTop: '1rem' }}>
                    <Notification message={showNotification[1]} type={showNotification[2]} onClose={closeNotification} />
                </div> : null}
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                    <button type="submit" className="adduser-btn-submit">Valider</button>
                </div>
            </form></> : <SpinLoader />}
    </div>);
}


const Header = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [showAdduser, setShowAdduser] = useState(false);

    const onShowAdduser = () => {
        setShowAdduser(!showAdduser);
    }

    return (
        <>
            <div className="search-bar-container">
                <div className="search-bar-btn-ajouteruser" onClick={onShowAdduser}><p>Ajouter un utilisateur</p></div>
                <input type="search" placeholder="Recherche utilisateur" className="search-input" value={searchParams.get('searchQuery') ?? ''} onChange={(e) => {
                    if (!e.target.value) {
                        setSearchParams({
                            ...searchParams.delete('searchQuery'),
                            page: 1
                        })
                    } else {
                        setSearchParams({
                            searchQuery: e.target.value,
                            page: 1
                        })
                    }
                }} />
            </div>
            {showAdduser ? <AddUser setShowAdduser={setShowAdduser} /> : null}
        </>
    )
}

const Body = ({ list }) => {
    const [showEditUser, setShowEditUser] = useState(false);
    const [selectedUser, setSelectedUser] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isFiltreDateInt, setIsFiltreDateInt] = useState(searchParams.get('orderdate') === 'true' ? true : false)
    

    const handleUserClick = (id) => {
        setSelectedUser(id);
        setShowEditUser(true);

    }

    useEffect(() => {
        setSearchParams({
            orderdate: isFiltreDateInt,
            page: searchParams.get('page') ?? 1,
            searchQuery: searchParams.get('searchQuery') ?? ''
        })
    }, [isFiltreDateInt])

    const handleFiltreDateInt = () => {
        setIsFiltreDateInt(!isFiltreDateInt)
    }

    return (
        <>
            <table className="listofcollaborators-table">

                <thead>
                    <tr className="listofcollaborators-table-tr">
                        <th>Collaborateur</th>
                        <th>Date d'intégration<div className="listofcollaborators-table-filterbydate" onClick={()=>handleFiltreDateInt()} style={{cursor:'pointer'}}><i class="bi bi-arrow-down-up"></i></div></th>
                        <th>Evaluations Restante</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.results?.map((evaluated, index) => (
                            <tr key={evaluated.id} onClick={() => handleUserClick(evaluated.id)} style={{ backgroundColor: index % 2 === 0 ? '#f4f6fa' : 'white', cursor: 'pointer' }} >
                                <td>
                                    <div className="listofcollaborators-table-employee-container">
                                        <img src={evaluated.profile_picture.replace('static/public/', '')} alt="avatar" width="60rem" height="60rem" class="rounded-circle" style={{ margin: '5px' }} />
                                        <div className="listofcollaborators-table-employee-identity">
                                            <div className="listofcollaborators-table-employee-name">{evaluated.first_name + ' ' + evaluated.last_name}</div>
                                            <div className="listofcollaborators-table-employee-poste">{evaluated.position?.intitule}</div>
                                        </div>
                                    </div>
                                </td>
                                <td><div className="listofcollaborators-table-info">{evaluated.date_integration}</div></td>
                                <td><div className="listofcollaborators-table-info">{(evaluated.total_evaluated==0)?"Aucun pour cette campagne":`${evaluated.remaining_evaluations}/${evaluated.total_evaluated}`}</div></td>
                            </tr>
                        ))}
                </tbody>
            </table>
            {showEditUser ? <EditUser setShowEditUser={setShowEditUser} userId={selectedUser} /> : null}
        </>
    )
}

const Footer = ({ pageCount }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLastPage, setIsLastPage] = useState(false)
    const [isFirstPage, setIsFirstPage] = useState(false)

    useEffect(() => {
        const page = Number(searchParams.get('page'))
        if (page) {
            setIsLastPage(Math.ceil(pageCount / 10) <= page)
            setIsFirstPage(page === 1)
        }
    }, [searchParams])


    return (
        <>
            <div className="listofcollaborators-pagehandler" >
                <div className="listofcollaborators-pagehandler-container">
                    <button type="button" className="listofcollaborators-pagehandler-btn" disabled={isFirstPage} onClick={() => {
                        setSearchParams({
                            searchQuery: searchParams.get('searchQuery') ?? '',
                            orderdate: searchParams.get('orderdate'),
                            page: 1
                        })
                    }}><i className="bi bi-chevron-double-left"></i></button>
                    <button type="button" className="listofcollaborators-pagehandler-btn" disabled={isFirstPage} onClick={() => {
                        setSearchParams({
                            searchQuery: searchParams.get('searchQuery') ?? '',
                            orderdate: searchParams.get('orderdate'),
                            page: Number(searchParams.get('page')) - 1
                        })
                    }}><i className="bi bi-chevron-left"></i></button>
                    <div className="listofcollaborators-pagehandler-page">{searchParams.get('page')}</div>
                    <button type="button" className="listofcollaborators-pagehandler-btn" disabled={isLastPage} onClick={() => {
                        setSearchParams({
                            searchQuery: searchParams.get('searchQuery') ?? '',
                            orderdate: searchParams.get('orderdate'),
                            page: Number(searchParams.get('page')) + 1
                        })
                    }}><i className="bi bi-chevron-right"></i></button>
                    <button type="button" className="listofcollaborators-pagehandler-btn" disabled={isLastPage} onClick={() => {
                        setSearchParams({
                            searchQuery: searchParams.get('searchQuery') ?? '',
                            orderdate: searchParams.get('orderdate'),
                            page: Math.ceil(pageCount / 10)
                        })
                    }}><i className="bi bi-chevron-double-right"></i></button>
                    {(Math.ceil(pageCount / 10) == 1)?'':<div className="listofcollaborators-pagehandler-page" style={{backgroundColor:'#f0f0f0', color: '#20418e'}}>{Math.ceil(pageCount / 10)}</div>}
                </div>
            </div >

        </>
    )
}



export const AdminUsers = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { isFetching, data: list, isPreviousData, refetch } = useQuery({
        enabled: true,
        queryKey: ['listofusers', searchParams.get('searchQuery'), searchParams.get('page'), searchParams.get('orderdate')],
        cacheTime: 24 * 60 * (60 * 1000),
        staleTime: 24 * 60 * (60 * 1000),
        queryFn: () => {
            return ax.get('users/', {
                params: {
                    search: (searchParams.get('searchQuery')?.length > 2) ? searchParams.get('searchQuery') : '',
                    orderdate: searchParams.get('orderdate') ?? false,
                    page: searchParams?.get('page') ?? 1,
                }
            }).then(res => {
                console.log("DATA FOR ADMIN USERS; ", res.data)
                // filter user with total_evaluated==0
                res.data.results = res.data.results.filter((user) => user.total_evaluated != 0);
                return res.data
            })
        }
    })


    useEffect(() => {
        if (!searchParams.get('page')) {
            setSearchParams({
                page: 1
            })
        }
        refetch();
    }, [])

    return (
        <div className="listofcollaborators-container">
            <Header />
            {isFetching ? <SpinLoader /> : !list?.results?.length ?
                <div className="listofcollaborators-content">
                    <div className="listofcollaborators-card" style={{ backgroundColor: 'white' }}>
                        <div className="listofcollaborators-card-content">
                            <div className="listofcollaborators-card-container">
                                <p className="card-title">Aucun collaborateur trouvé.</p>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <>
                    <Body list={list} />
                    <Footer pageCount={list?.count} />
                </>
            }
        </div>
    );
};
