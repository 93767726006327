import axios from "axios";

let refresh = false;
export let root_api = 'https://rcdac-api.cosumar.app/api/'.replace('/api/', '');

const getToken = () => localStorage.getItem("access_token")
    ? localStorage.getItem("access_token")
    : null;


const getAuthorizationHeader = () => `Bearer ${getToken()}`;

const ax = axios.create({
    baseURL: 'https://rcdac-api.cosumar.app/api/', // ?? 'http://10.1.7.11:8000/api/',
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    },
})

ax.interceptors.request.use(
    (config) => {
        config.headers["Authorization"] = getAuthorizationHeader();
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


ax.interceptors.response.use(resp => resp, async error => {
    if (error.response.status === 403 && !refresh && localStorage.getItem('refresh_token')) {
        refresh = true;
        console.log(localStorage.getItem('refresh_token'))
        const response = await ax.post('/token/refresh/', {
            refresh: localStorage.getItem('refresh_token')
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        }, { withCredentials: true });

        if (response.status === 200) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${response.data['access']}`;
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);
            return axios(error.config);
        } else {
            localStorage.clear()
        }
    }
    refresh = false;
    return error;
});


export default ax;
