import React, { useState, useEffect } from 'react';

export function Page404() {


  return (
    <p className='page404notfound'>404 NOT FOUND</p>
  );

}
export default Page404;