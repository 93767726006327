import { useEffect, useState } from "react";
import ax, {root_api} from '../interceptors/axios';
import { Link, Navigate } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import { SpinLoader } from "./spinLoader";


const SkillsList = ({ skillcategory, searchQuery, handleClick, categoryID }) => {
    console.log("skill list: ", skillcategory, categoryID)
    return <>
        {skillcategory && (skillcategory.length > 0) ? skillcategory.map((skillcategory, index) => (
            <button key={skillcategory.id} className="dictofskill-content-button" onClick={() => handleClick(skillcategory.id, index)}>
                <div key={skillcategory.id} className="dictofskill-content-category-list-item" style={(categoryID == null && searchQuery.length < 3)?{minHeight:'7rem'}:{}}>
                    {(categoryID == null && searchQuery.length < 3) ? <img className="dictofskill-content-category-list-item-img" src={skillcategory.icon?.replace('static/public/', '')?.replace('-api', '')} alt="avatar" /> : <div></div>}
                    <div className="dictofskill-content-category-list-item-info">
                        <div className="dictofskill-content-category-list-item-title" style={(categoryID == null && searchQuery.length < 3)?{fontSize:'1.5rem'}:{}}>{skillcategory.title}</div>
                        {(categoryID == null && searchQuery.length < 3) ? <div className="dictofskill-content-category-list-item-description" style={(categoryID == null && searchQuery.length < 3)?{fontSize:'0.8rem'}:{}}>{skillcategory.description}</div> : <div></div>}
                    </div>
                    <div className="dictofskill-content-category-list-item-icon"><i className="bi bi-box-arrow-in-right"></i></div>
                </div>
            </button>
        )) : <p className="card-title">Aucune compétence pour cette catégorie.</p>
        }</>
};

const SkillMetadata = ({ skillData }) => {

    return <>
        <div className="dictofskill-content-category-list-item">
            <div className="dictofskill-content-category-list-item-info-skill" style={{flexDirection:"column"}}>
                <div className="dictofskill-content-category-list-item-skilltitle" style={{fontSize:"1.1rem", marginRight:"auto", paddingLeft:"2.45rem"}}>Définition</div>
                <div className="dictofskill-content-category-list-item-metadata" style={{fontSize:"1rem"}}>{skillData.description}</div>
            </div>
        </div>
        <div className="dictofskill-content-category-list-item">
            <div className="dictofskill-content-category-list-item-info-skill">
                <div className="dictofskill-content-category-list-item-skilltitle"><img src="emojiLvl1.png" className="dictofskill-content-category-list-item-lvlicon"></img>Débutant (1)</div>
                <div className="dictofskill-content-category-list-item-metadata" style={{whiteSpace: "pre-wrap"}}>{skillData.metadata.level_1}</div>
            </div>
        </div>
        <div className="dictofskill-content-category-list-item" style={{ backgroundColor: 'transparent' }}>
            <div className="dictofskill-content-category-list-item-info-skill">
                <div className="dictofskill-content-category-list-item-skilltitle"><img src="emojiLvl2.png" className="dictofskill-content-category-list-item-lvlicon" style={{marginLeft:'-0.5rem'}}></img>Qualifié (2)</div>
                <div className="dictofskill-content-category-list-item-metadata" style={{whiteSpace: "pre-wrap"}}>{skillData.metadata.level_2}</div>
            </div>
        </div>
        <div className="dictofskill-content-category-list-item">
            <div className="dictofskill-content-category-list-item-info-skill">
                <div className="dictofskill-content-category-list-item-skilltitle"><img src="emojiLvl3.png" className="dictofskill-content-category-list-item-lvlicon" style={{marginLeft:'-0.5rem'}}></img>Confirmé (3)</div>
                <div className="dictofskill-content-category-list-item-metadata" style={{whiteSpace: "pre-wrap"}}>{skillData.metadata.level_3}</div>
            </div>
        </div>
        <div className="dictofskill-content-category-list-item" style={{ backgroundColor: 'transparent' }}>
            <div className="dictofskill-content-category-list-item-info-skill">
                <div className="dictofskill-content-category-list-item-skilltitle"><img src="emojiLvl4.png" className="dictofskill-content-category-list-item-lvlicon"></img>Expert (4)</div>
                <div className="dictofskill-content-category-list-item-metadata" style={{whiteSpace: "pre-wrap"}}>{skillData.metadata.level_4}</div>
            </div>
        </div>
    </>
};

const SkillPath = ({ categoryTitle, subCategoryTitle, skillTitle, categoryID, subCategoryID, skillID, handleReturn }) => {

    return <> {(categoryID === null) ? <div></div> :
        <div className="dictofskill-content-category-title">
            <div className="dictofskill-content-category-title">
                <div onClick={() => handleReturn(null, null, null)}>{`Compétences ${categoryTitle.toLowerCase()}`}</div>
                <div onClick={() => handleReturn(1, null, null)}>{`${(subCategoryID === null) ? '' : `> ${subCategoryTitle.toLowerCase()}`}`}</div>
                <div onClick={() => handleReturn(1, 1, null)}>{`${(skillID === null) ? '' : `> ${skillTitle.toLowerCase()}`}`}</div>
            </div>
        </div>}</>
};

const Header = (searchQuery, handleReturn, categoryID, subCategoryID, skillID, handleInputSearchChange) => {

    const onInputChange = (event) => {
        handleInputSearchChange(event.target.value);
    };
    
    return <>
        <p className="title-page">Dictionnaire des<br></br>compétences</p>
        <div className="search-bar-container">
            {searchQuery === '' && categoryID !== null ? <button className="dictofskill-return-btn" onClick={() => handleReturn(categoryID, subCategoryID, skillID, true)}><i class="bi bi-arrow-left-circle"></i></button> : <div></div>}
            <input type="search" placeholder="Recherche" className="search-input" value={searchQuery} onChange={(event) => onInputChange(event)} />

        </div>
    </>
};

export const DictOfSkills = () => {
    const [skillcategory, setSkillCategory] = useState(null);
    const [categoryID, setCategoryID] = useState(null);
    const [subCategoryID, setSubCategoryID] = useState(null);
    const [skillID, setSkillID] = useState(null);
    const [categoryTitle, setCategoryTitle] = useState(null);
    const [subCategoryTitle, setSubCategoryTitle] = useState(null);
    const [skillTitle, setSkillTitle] = useState(null);
    const [skillData, setSkillData] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if (localStorage.getItem('access_token') === null) {
            window.location.href = '/login'
        }
        else {
            (async () => {
                try {
                    const searchQueryParam = (searchQuery === '' || searchQuery.length < 3) ? '' : `search=${searchQuery}`
                    if (searchQueryParam !== '') {
                        var _url = `/skill/lookup/?${searchQueryParam}`;
                    } else {
                        console.log("CATEGORY ID = ", categoryID, subCategoryID, skillID)
                        if (categoryID === null) {
                            var _url = '/skillcategory/';
                        } else if (categoryID != null && subCategoryID === null) {
                            var _url = `/skillcategory/${categoryID}/skill_sub_categories/`;
                        } else if (categoryID !== null && subCategoryID !== null && skillID === null) {
                            var _url = `/skillsubcategory/${subCategoryID}/skills/`;
                        } else if (categoryID !== null && subCategoryID !== null && skillID !== null) {
                            var _url = `/skillmetadata/${skillID}/`;
                        }
                    }
                    const { data } = await ax.get(_url);
                    if (skillID !== null) {
                        if (searchQueryParam !== '') {
                            var _skill = data.filter((skill) => skill.id === skillID)[0];
                            setCategoryID(_skill.skill_category.id);
                            setSubCategoryID(_skill.skill_subcategory.id);
                            setCategoryTitle(_skill.skill_category.title)
                            setSubCategoryTitle(_skill.skill_subcategory.title);
                            setSkillTitle(_skill.title);
                            setSkillData(_skill);
                            setSearchQuery('');
                        } else {
                            setSkillData(data);
                        }

                    } else if (data.results !== undefined) {
                        setSkillCategory(data.results);
                    } else {
                        setSkillCategory(data);
                    }
                    console.log('Skill:', data)
                } catch (e) {
                    console.error('Error fetching category list:', e);
                }
            })();
        };
    }, [searchQuery, categoryID, subCategoryID, skillID]);

    const handleClick = (id, index) => {
        if (searchQuery.length >= 3) {
            setSkillID(id);
        } else {
            if (categoryID === null) {
                setCategoryTitle(skillcategory[index].title);
                setCategoryID(id);
            } else if (categoryID !== null && subCategoryID === null) {
                setSubCategoryTitle(skillcategory[index].title);
                setSubCategoryID(id);
            } else if (categoryID !== null && subCategoryID !== null) {
                setSkillID(id);
                setSkillTitle(skillcategory[index].title);
            }
        }
    };

    const handleReturn = (_category, _subCategory, _skill, isBtn = false) => {
        if (isBtn) {
            if (_skill != null) {
                setSkillData(null);
                setSkillID(null);
            } else if (_skill == null && _subCategory != null) {
                setSubCategoryID(null);
                setSkillData(null);
                setSkillID(null);
            } else if (_skill == null && _subCategory == null && _category != null) {
                setCategoryID(null);
                setSubCategoryID(null);
                setSkillData(null);
                setSkillID(null);
            }
        } else {
            if (_skill == null && (_category != null && _subCategory != null)) {
                setSkillData(null);
                setSkillID(null);
            } else if (_category != null && _subCategory == null) {
                setSubCategoryID(null);
                setSkillData(null);
                setSkillID(null);
            } else if (_category == null) {
                setCategoryID(null);
                setSubCategoryID(null);
                setSkillData(null);
                setSkillID(null);
            }
        }
    };

    const handleInputSearchChange = (event) =>{
        setCategoryID(null);
        setSubCategoryID(null);
        setSkillID(null);
        setSkillData(null);
        setSearchQuery(event.target.value);
    };

    return <div className="dictofskill-container">
        <p className="title-page">Dictionnaire des<br></br>compétences</p>
        <div className="search-bar-container">
            {searchQuery === '' && categoryID !== null ? <button className="dictofskill-return-btn" onClick={() => handleReturn(categoryID, subCategoryID, skillID, true)}><i class="bi bi-arrow-left-circle"></i></button> : <div></div>}
            <input type="search" placeholder="Recherche" className="search-input" value={searchQuery} onChange={(event) => handleInputSearchChange(event)} />

        </div>
        {skillcategory === null ? <SpinLoader /> :
            <div className="dictofskill-content">
                <SkillPath categoryTitle={categoryTitle} subCategoryTitle={subCategoryTitle}
                    skillTitle={skillTitle} categoryID={categoryID} subCategoryID={subCategoryID}
                    skillID={skillID} handleReturn={handleReturn} />
                <div className="dictofskill-content-category">
                    <div className="dictofskill-content-category-list">
                        {(skillID !== null && skillData) ? <SkillMetadata skillData={skillData} /> :
                            <SkillsList skillcategory={skillcategory} searchQuery={searchQuery}
                                handleClick={handleClick} categoryID={categoryID} />}
                    </div>
                </div>
            </div>
        }
        <div className="dictofskill-footer">
        </div>
    </div>
}